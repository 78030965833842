import Service from '@/utils/request.js'

/**
 * 公告详情
 * @param {*} params
 * @returns
 */
export const homeDetail = (params) => Service.post('/home/detail', params)
/**
 * 公告列表
 * @param {*} params
 * @returns
 */
export const homeListPublic = (params) => Service.post('/home/listPublic', params)
/**
 * 经销公告列表
 * @param {*} params
 * @returns
 */
export const homeListSell = (params) => Service.post('/home/listSell', params)
/**
 * 制度列表
 * @param {*} params
 * @returns
 */
export const homeListSystem = (params) => Service.post('/home/listSystem', params)
/**
 * 店长公告列表
 * @param {*} params
 * @returns
 */
export const homeListDz = (params) => Service.post('/home/listDz', params)
/**
 * 最新公告列表
 * @param {*} params
 * @returns
 */
export const homeListPublicNew = (params) => Service.post('/home/listPublicNew', params)
/**
 * 经理公告列表
 * @param {*} params
 * @returns
 */
export const homeListJl = (params) => Service.post('/home/listJl', params)
/**
 * 弹窗公告
 * @param {*} params
 * @returns
 */
export const homeTipPublic = (params) => Service.post('/home/tipPublic', params)
/**
 * 弹窗公告确认
 * @param {*} params
 * @returns
 */
export const homeTipPublicConfig = (params) => Service.post('/home/tipPublicConfig', params)
/**
 * 问题列表
 * @param {*} params
 * @returns
 */
export const qaList = (params) => Service.post('/qa/list', params)
/**
 * 新建问题
 * @param {*} params
 * @returns
 */
export const qaAdd = (params) => Service.post('/qa/add', params)

/**
 * 新店长公告列表
 */
export const itemHomeListDz = (params) => Service.post('/itemHome/listDz', params)

/**
 * 中层公告列表
 */
export const itemHomeListZc = (params) => Service.post('/itemHome/listZc', params)
