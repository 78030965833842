import ReturnPage from '@/views/return-page' // 返回页面结构
export default [{
  path: '/company-homepage',
  name: 'return-company-homepage',
  component: ReturnPage,
  children: [
    {
      path: 'homepage-article/:classId/:type',
      name: 'homepage-article',
      component: () => import('@/views/company-home/components/company-homepage/homepage-article.vue'),
      meta: { title: '文章详情', rekeepAlive: true, scrollTop: 0, name: 'homepageArticle' },
      props: true
    },
    {
      path: 'old-homepage/:type?',
      name: 'old-homepage',
      component: () => import('@/views/company-home/components/old-homePage/index.vue'),
      meta: { title: '公告', rekeepAlive: true, scrollTop: 0, name: 'oldHomepage' },
      props: true
    }
  ]
}]
