<template>
  <div class="datepicker">
    <van-field
      v-model="text"
      :name="name"
      readonly
      :required="required"
      :disabled="disabled"
      :class="required?'input_req':''"
      :label="label"
      :placeholder="placeholder"
      @click="!disabled?(showPicker = true):''"
      :rules="rules"
    >
    </van-field>
    <Teleport :to="TeleportId" :disabled="!!TeleportId">
      <van-popup v-model="showPicker" position="bottom" class="datepicker_popup">
        <van-datetime-picker
          v-model="currentDate"
          :type="type"
          :title="title"
          :min-date="minDate"
          :max-date="maxDate"
           :min-hour="0"
          :max-hour="23"
          @confirm="onConfirm"
        >
          <template #cancel>
            <slot name="cancel"><span @click="onCancel">{{ cancelText }}</span></slot>
          </template>
        </van-datetime-picker>
      </van-popup>
    </Teleport>
  </div>
</template>

<script>
import { getDate, getOriginalDate } from '@/utils/index'
import Teleport from '@/components/Teleport.vue'
export default {
  name: 'datePicker',
  props: {
    type: { // 日期类型 默认 年月日
      type: String,
      default: 'date'
    },
    title: { // 选择器标题
      type: String,
      default: '选择日期'
    },
    name: { // 文本框的 name
      type: String
    },
    value: {
      default: '',
      type: String
    },
    label: { // label 名称
      default: '选择器',
      type: String
    },
    placeholder: { // 提示内容
      default: '点击选择',
      type: String
    },
    rules: { // 效验规则
      type: Array,
      default: () => []
    },
    required: { // 必填
      type: Boolean,
      default: false
    },
    minDate: {
      type: Date,
      default: () => new Date(1, 1, 1)
    },
    maxDate: {
      type: Date,
      default: () => new Date()
    },
    defTime: { // 点击选择器之后显示的默认值
      type: String,
      default: ''
    },
    disabled: { // 只读
      type: Boolean,
      default: false
    },
    cancelText: { // 取消按钮的文本
      type: String,
      default: '取消'
    },
    /**  由于 vant 没有只选 年份的选择器 这个控制 隐藏月份 实现只选择年份
     * 同时 type 属性需要传 year-date 年月类型 当前 info 属性才会生效
     * year 是选择年份的
     * 如果是同个组件需要更换选择器的选择类型
     * 就需要在更改的时候 传 no
     * 而不是传空 或 不传
     * */
    info: {
      type: String,
      default: ''
    },
    TeleportId: { // 传送元素id
      type: String,
      default: ''
    }
  },
  provide: function () {
    return {
      parent: this
    }
  },
  data () {
    return {
      text: '',
      currentDate: this.defTime ? getOriginalDate(this.defTime) : new Date(), // 默认值
      showPicker: false,
      isTeleport: false
    }
  },
  computed: {
    calcInfo () {
      return this.info
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler (val) {
        this.text = val
        this.currentDate = this.type == 'time' ? val : val ? getOriginalDate(val) : this.defTime ? getOriginalDate(this.defTime) : new Date()
        // console.log(this.currentDate)
      }
    },
    text: {
      deep: true,
      handler (newVal, oldVal) {
        if (newVal != oldVal && newVal != this.value) {
          this.$emit('onConfirm', this.text)
        }
      }
    },
    showPicker: {
      handler (newVal, oldVal) {
        if (newVal && this.type == 'year-month' && this.calcInfo == 'year') {
          this.$nextTick(() => {
            const columns = document.querySelectorAll('.datepicker_popup .van-picker__columns .van-picker-column')
            if (columns.length == 2) {
              setTimeout(() => {
                columns[1].style.display = 'none'
              }, 100)
            }
          })
        } else if (this.calcInfo == 'no') {
          this.$nextTick(() => {
            const columns = document.querySelectorAll('.datepicker_popup .van-picker__columns .van-picker-column')
            if (columns.length == 2) {
              setTimeout(() => {
                columns[1].style.display = 'block'
              }, 100)
            }
          })
        }
      }
    }
  },
  methods: {
    onConfirm (val) { // 确定
      this.type == 'date' && (this.text = getDate(val, 1).trim()) // 显示 年月日
      this.type == 'year-month' && (this.text = getDate(val, 'month').trim()) // 显示年月
      this.type == 'datetime' && (this.text = getDate(val).trim()) // 显示完整时间
      this.type == 'time' && (this.text = val) // 显示 时间
      this.showPicker = false
    },
    onCancel (val) {
      // this.text = ''
      this.$emit('cancel')
      this.showPicker = false
    }
  },
  components: {
    Teleport
  }
}
</script>

<style lang="less" scoped>
.datepicker{
  position: relative;
}
.datepicker::after{
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #ebedf0;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
}
</style>
