
const getters = {
  token: state => state.user.token, // token
  titleHome: state => state.user.titleHome,
  statusIndex: state => state.user.statusIndex, // 导航栏状态
  statusOrder: state => state.user.statusOrder, // 我的订单状态
  dropdown: state => state.user.dropdown, // 我的订单添加订单状态
  dropdown2: state => state.user.dropdown2, // 我的订单添加订单2状态
  classId: state => state.user.classId, // 是否经销商
  unreadNum: state => state.user.unreadNum, // 存储 消息列表未读数量 与 待签核数量
  passwordStatus: state => state.user.unreadNum, // 密码过期修改的状态
  sellCustNullTelCodeBool: state => !state.user.userInfo.memuCodeList.includes('sell:cust:nullTel'), // 客户手机号选题  宫爱学
  privateUnits: state => state.user.privateUnits // 客户手机号选题  宫爱学
}
export default getters
