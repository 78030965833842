<template>
  <div id="home">
    <!-- 头部 -->
    <transition name="hg2">
      <van-nav-bar ref="home" v-if="homeShow" left-text="" fixed :left-arrow="false" id="home-header">
        <template #left>
          <span v-on:click="onShowToolbar">
            <van-icon name="arrow-up" size="24px" v-show="tabshow"/>
            <van-icon name="arrow-down" size="24px" v-show="!tabshow"/>
          </span>
        </template>
        <template #title>
          <div class="box-title">
            <span>{{title}}</span>
            <span class="h3-title" id="minTitle"></span>
          </div>
        </template>
        <template #right>
          <div id="Teleport">
            <!-- <span @click="onSignout">退出登录</span> -->
          </div>
        </template>
      </van-nav-bar>
    </transition>
    <!-- 主内容区 -->
    <div class="main scroll_hide" id="main" ref="main">
      <!-- 动态缓存 -->
      <keep-alive :include="clacInclude">
        <router-view></router-view>
      </keep-alive>
    </div>
    <!-- 底部导航栏 -->
    <transition name="hg">
      <van-tabbar v-model="active" v-if="tabshow" route @change="onChange">
        <van-tabbar-item name="首页" replace to="/homepage" icon="home-o">首页</van-tabbar-item>
        <van-tabbar-item name="工作台" to="/homework" icon="qr" :dot="!!(unreadNum.signing || unreadNum.tosign)">工作台</van-tabbar-item>
        <!-- 本集团 -->
        <template v-if="!pgroupId">
          <van-tabbar-item name="工作流" to="/processSignOff/workflow" icon="points"  :dot="!!(unreadNum.notPayOrder||unreadNum.flowRemindCount)">工作流</van-tabbar-item>
          <van-tabbar-item name="日志" to="/journal/daily" icon="records">日志</van-tabbar-item>
          <van-tabbar-item v-if="!!$store.state.user.userInfo.proleName"  name="订单" to="/purchase-order/my-order" icon="description">订单</van-tabbar-item>
        </template>
        <!-- 没有本集团权限 -->
        <template v-else-if="hasAuthentication=0">
          <van-tabbar-item v-if="$store.state.user.userInfo.proleName" name="订单" to="/purchase-order/my-order" icon="description">订单</van-tabbar-item>
          <van-tabbar-item name="购物车" to="/purchase-order/shopping-cart" icon="cart-o">购物车</van-tabbar-item>
        </template>
        <template v-else>
          <van-tabbar-item name="工作流" to="/processSignOff/workflow" icon="points"  :dot="!!(unreadNum.notPayOrder||unreadNum.flowRemindCount)">工作流</van-tabbar-item>
          <van-tabbar-item name="日志" to="/journal/daily" icon="records">日志</van-tabbar-item>
          <van-tabbar-item v-if="!!$store.state.user.userInfo.proleName"  name="订单" to="/purchase-order/my-order" icon="description">订单</van-tabbar-item>
          <!-- <van-tabbar-item name="购物车" to="/purchase-order/shopping-cart" icon="cart-o">购物车</van-tabbar-item> -->
        </template>
        <van-tabbar-item name="我的" to="/system/personal" icon="user-o" :dot="!!(hasSchedule || unreadNum.listNum)">我的</van-tabbar-item>
      </van-tabbar>
    </transition>
    <van-action-sheet v-model="turnoverShow" title="登记营业额">
      <RegisteredTurnover :list="clacTurnover" v-if="turnoverShow" @onConfim="onConfim"/>
    </van-action-sheet>
    <!-- 传送门 最新公告 -->
    <Teleport v-if="isShow" to="#T_latest_announcement" :disabled="isTeleport">
      <div class="overlay">
        <van-overlay :show="overlayShow">
          <div class="wrapper" @click.stop>
            <transition name="van-slide-down">
              <div class="block" v-if="blockShow">
                <LatestNoticeSystem :overlayList="overlayList" :type="overlayList.sysTipUp == 1" @close="onClose"/>
              </div>
            </transition>
          </div>
        </van-overlay>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { getUserInfo } from '@/api/user'
import { homeTipPublic } from '@/api/home/page'
// import { mapGetters } from 'vuex'
import { getUnreadQuantity } from './js'
import RegisteredTurnover from '@/views/store-management/components/performance-management/performance-reporting/RegisteredTurnover.vue'
import Teleport from '@/components/Teleport.vue'
import LatestNoticeSystem from './components/LatestNoticeSystem.vue'
import { parseFn } from '@/utils'
export default {
  name: 'home',
  data () {
    return {
      title: '',
      tabshow: true,
      homeShow: true,
      turnoverShow: false,
      navshow: false,
      active: '首页',
      menuListAll: [],
      divHeight: 0,
      windowHeight: 0, // 屏幕高度
      class_Id: '', // 经销商2无仓库
      pgroupId: '', // 下级经销商集团
      hasAuthentication: '', // 本集团权限
      groupId: '', // 集团
      include: [],
      isShow: false,
      resetTitleBool: false,
      overlayShow: false,
      blockShow: false,
      isTeleport: true,
      overlayList: {}
    }
  },
  computed: {
    // ...mapGetters(['statusIndex']),
    unreadNum () {
      return this.$store.state.user.unreadNum
    },
    hasSchedule () {
      return this.$store.state.user.userInfo.hasSchedule
    },
    calcShow () {
      return this.$store.state.user.statusIndex
    },
    clacInclude () {
      return this.$store.state.dynamicCache.includes
    },
    clacTurnover () {
      return this.$store.state.user.userInfo.needPaymentManualAdd
    }
  },
  watch: {
    $route (to, from) {
      if (to.name !== 'my-order') { // 清除我的订单状态
        this.$store.dispatch('user/cleanupStatusOrder')
      }
      this.$nextTick(() => {
        if (to.meta.title && !this.resetTitleBool) { // 动态显示标题
          this.title = to.meta.title
        }
        this.resetTitleBool = false
      })
      if (to.meta.keepAlive) { // 获取长时间需要缓存组件 并 添加进缓存序列中
        to.meta.long && this.$store.dispatch('dynamicCache/setLongKeepAlive', to.meta.name)
        !to.meta.long && this.$store.dispatch('dynamicCache/setKeepAlive', to.meta.name)
      }
    },
    calcShow (val) {
      this.tabshow = val
    },
    tabshow () {
      this.$store.dispatch('user/getStatusIndex', this.tabshow)
    }
  },
  created () {
    this.getGetUserInfo()
    this.title = this.$route.meta.title
    // 控制首页底部
    this.tabshow = this.$store.state.user.statusIndex || true
    this.$store.commit('user/setStatusIndex', this.tabshow)
    this.$store.dispatch('dynamicCache/setKeepAliveFrame', 'home') // 进入页面缓存当前页
    if (this.$route.meta.keepAlive) {
      this.$route.meta.long && this.$store.dispatch('dynamicCache/setLongKeepAlive', this.$route.meta.name) // 获取长时间需要缓存组件 并 添加进缓存序列中
      !this.$route.meta.long && this.$store.dispatch('dynamicCache/setKeepAlive', this.$route.meta.name) // 获取长时间需要缓存组件 并 添加进缓存序列中
    }
  },
  mounted () {
    this.$refs.main.style.paddingBottom = this.tabshow ? '50px' : '0px'
    // 兼容移动端上拉浏览变动
    window.onresize = () => {
      return (() => {
        this.$refs.main.style.paddingBottom = this.tabshow ? '50px' : '0px'
      })()
    }
  },
  methods: {
    async getGetUserInfo () {
      try {
        this.$store.dispatch('user/setPowerStatus', '0')
        this.$store.commit('loading/showLoading', { name: '加载中, 请稍后...', logInfo: true })
        const { data: res } = await getUserInfo()
        this.class_Id = res.classId
        this.pgroupId = res.pgroupId
        this.groupId = res.groupId
        this.hasAuthentication = res.hasAuthentication
        this.$store.dispatch('user/setUserInfo', res)
        this.$store.dispatch('user/getClassId', this.class_Id)
        this.$store.commit('loading/hideLoading', true)
        this.$store.dispatch('user/setPowerStatus', '1')
        // 获取两个未读数量
        getUnreadQuantity()
        this.$nextTick(() => {
          this.getHomeTipPublic().then((res) => {
            if (res) {
              // 没有公告之后
              // 登记营业额
              const obj = this.$store.state.user.userInfo
              this.turnoverShow = this.clacTurnover ? !!this.clacTurnover.length : false
              if (!this.turnoverShow && obj.sellOrderFlowList && obj.sellOrderFlowList.length > 0) {
                this.$dialog.confirm({
                  message: '您有流程' + obj.sellOrderFlowList[0].idCust + (obj.sellOrderFlowList.length > 1 ? '等等要处理' : '要处理')
                })
              }
            }
          })
        })
      } catch (err) {
        this.$store.dispatch('user/setPowerStatus', '2')
        this.$store.commit('loading/hideLoading', true)
      }
      this.getPageLoadForTheFirstTime()
    },
    async getHomeTipPublic () { // 获取是否有最新公告
      try {
        const { data: res } = await homeTipPublic()
        if (res.length) {
          this.overlayList = res[0]
          this.isShow = true
          this.overlayShow = true
          setTimeout(() => {
            this.blockShow = true
          }, 100)
          return false
        } else {
          return true
        }
      } catch (err) {
        this.isShow = false
        this.overlayShow = false
        this.blockShow = false
        console.log(err)
        return true
      }
    },
    onClose () { // 关闭公告回调
      this.isShow = false
      this.overlayShow = false
      this.blockShow = false
      // 关闭公告之后再判断是否有营业额需要登记
      // 登记营业额
      this.turnoverShow = this.clacTurnover ? !!this.clacTurnover.length : false
    },
    onShowToolbar () { // 控制菜单栏的显示隐藏
      this.tabshow = !this.tabshow
      this.$refs.main.style.paddingBottom = this.tabshow ? '50px' : '0px'
    },
    onChange (index) { // 修改头部标题
      // console.log(this.title)
      this.title = parseFn(index)
      this.resetTitleBool = true
    },
    onSignout () { // 退出登录
      this.$dialog.confirm({
        message: '确定要退出登录？'
      }).then(res => {
        this.$store.dispatch('user/cleanupData') // 清理vuex中的数据
        this.$router.push('/login')
        window.location.reload()
      }).catch(() => {})
    },
    onConfim (val) { //
      this.turnoverShow = false
    },
    getPageLoadForTheFirstTime () {
      if (window.name == '') {
        // console.log('首次加载')
        window.name = 'isReload'
      } else if (window.name == 'isReload') {
        // console.log('页面被刷新')
      }
    }
  },
  components: {
    RegisteredTurnover,
    Teleport,
    LatestNoticeSystem
  },
  // 动态缓存页面实现详情页回到原先页面回到原先浏览位置
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.$store.state.user.powerStatus == '请刷新界面...') {
        vm.getGetUserInfo()
      }
    })
  },
  // 离开页面
  beforeRouteLeave (to, from, next) {
    next()
  }
}
</script>

<style lang="less" scoped>
  /deep/.van-ellipsis{
    overflow: visible;
  }
  .box-title{
    position: relative;
    .h3-title{
      position: absolute;
      font-size: 0.6rem;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      color: rgb(134, 134, 134)
    }
  }
  .main{
    padding-top: 46px;
    min-height: 100vh;
    box-sizing: border-box;
    background: #fff;
    -ms-overflow-style:none;
    scrollbar-width:none;
    position: relative;
    &::-webkit-scrollbar{
      display:none;
    }
    &>div{
      min-height: 100%;
    }
  }
  .hg-enter,
  .hg-leave-to {
    transform: translateY(100%);
  }
  .hg-enter-active,
  .hg-leave-active {
    transition: all 0.1s linear;
  }

  .hg2-enter {
    transform: translateY(100%);
  }
  .hg2-leave-to {
    transform: translateY(-100%);
  }
  .hg2-enter-active,
  .hg2-leave-active {
    transition: all 0.1s linear;
  }
</style>
