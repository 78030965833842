const state = {
  appIncludes: [], // app页面需要缓存的组件
  longTermCache: [], // 需要长时间缓存组件
  includes: [], // 首页缓存
  returnPage: [] // 返回页缓存
}
const mutations = {
  setKeepAliveFrame (state, data) { // 缓存总体框架
    if (!state.appIncludes.includes(data)) {
      state.appIncludes.push(data)
    }
  },
  removeKeepAliveFrame (state, data) { // 缓存总体框架
    const res = state.appIncludes.filter(item => {
      return item != data
    })
    state.appIncludes = res
  },
  setLongKeepAlive (state, data) { // 需要长时间缓存组件
    if (!state.longTermCache.includes(data)) {
      state.longTermCache.push(data)
      const arr = [...state.includes, ...state.longTermCache, data]
      state.includes = Array.from(new Set(arr))
    }
  },
  setKeepAlive (state, data) { // 动态存储缓存组件
    if (!state.includes.includes(data)) {
      const arr = [...state.includes, ...state.longTermCache, data]
      state.includes = Array.from(new Set(arr))
    }
  },
  removeKeepAlive (state, data) { // 清除缓存组件
    const res = state.includes.filter(item => {
      return item != data
    })
    state.includes = res
  },
  setReturnKeepAlive (state, data) { // 返回页 动态存储缓存组件
    if (!state.returnPage.includes(data)) {
      const arr = [...state.returnPage, data]
      state.returnPage = Array.from(new Set(arr))
    }
  },
  removeReturnKeepAlive (state, data) { // 返回页 清除缓存组件
    const res = state.returnPage.filter(item => {
      return item != data
    })
    state.returnPage = res
  }
}
const actions = {
  setKeepAliveFrame (store, data) { // 缓存总体框架
    store.commit('setKeepAliveFrame', data)
  },
  removeKeepAliveFrame (store, data) { // 缓存总体框架
    store.commit('removeKeepAliveFrame', data)
  },
  setLongKeepAlive (store, data) {
    store.commit('setLongKeepAlive', data) // 缓存总体框架
  },
  setKeepAlive (store, data) { // 动态存储缓存组件
    store.commit('setKeepAlive', data)
  },
  removeKeepAlive (store, data) { // 清除缓存组件
    store.commit('removeKeepAlive', data)
  },
  setReturnKeepAlive (store, data) { // 返回页 动态存储缓存组件
    store.commit('setReturnKeepAlive', data)
  },
  removeReturnKeepAlive (store, data) { // 返回页 清除缓存组件
    store.commit('removeReturnKeepAlive', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
