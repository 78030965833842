import Homelayout from '@/views/home' // 主页面结构

export default [{
  path: '/my-service',
  component: Homelayout,
  children: [
    {
      path: 'my-order',
      name: 'myOrder',
      component: () => import('@/views/my-service/my-order.vue'),
      meta: { title: '我的订单' }
    },
    {
      path: 'shopping-cart',
      name: 'shopping-cart',
      component: () => import('@/views/my-service/shopping-cart.vue'),
      meta: { title: '购物车', keepAlive: true, scrollTop: 0, name: 'shoppingCart' }
    },
    {
      path: 'advance-payment',
      name: 'advance-payment',
      component: () => import('@/views/my-service/advance-payment.vue'),
      meta: { title: '预存贷款', keepAlive: true, scrollTop: 0, name: 'advancePayment' }
    },
    {
      path: 'my-collection',
      name: 'my-collection',
      component: () => import('@/views/my-service/my-collection.vue'),
      meta: { title: '我的收藏' }
    }
  ]
}]
