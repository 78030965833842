<template>
  <div class="loading">
    <van-loading type="spinner" color="#1989fa">{{ value }}</van-loading>
  </div>
</template>

<script>
export default {
  props: ['value'],
  name: 'LOADING',
  data () {
    return {}
  }

}

</script>

<style lang="less" scoped>

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>
