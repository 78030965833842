import Homelayout from '@/views/home' // 主页面结构
// import ReturnPage from '@/views/return-page' // 返回页面结构

export default [{
  path: '/customer-management',
  component: Homelayout,
  children: [
    {
      path: 'customer-registration',
      name: 'customer-registration',
      component: () => import('@/views/customer-management/customer-registration.vue'),
      meta: { title: '客户登记' }
    },
    {
      path: 'customer-list',
      name: 'customer-list',
      component: () => import('@/views/customer-management/customer-list.vue'),
      meta: { title: '客户列表', keepAlive: true, scrollTop: 0, name: 'customerList' }
    },
    {
      path: 'high-seas-customers',
      name: 'high-seas-customers',
      component: () => import('@/views/customer-management/high-seas-customers.vue'),
      meta: { title: '公海客户', keepAlive: true, scrollTop: 0, name: 'highSeasCustomers' }
    }
  ]
}]
