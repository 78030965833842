import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import getters from './getters'
import user from './modules/user'
import order from './modules/order'
import loading from './modules/loading'
import dynamicCache from './modules/dynamicCache'
import temporaryData from './modules/temporaryData'
import faceData from './modules/faceData'
import GlobalSettings from './modules/GlobalSettings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    order,
    loading,
    faceData,
    dynamicCache,
    temporaryData,
    GlobalSettings
  },
  getters,
  plugins: [createPersistedState({ // 防止页面刷新时数据丢失
    // storage: window.localStorage,
    reducer (val) {
      return {
        user: Object.assign({}, val.user, { userInfo: Object.assign({}, val.user.userInfo, { memuCodeList: [] }) }),
        order: val.order,
        temporaryData: val.temporaryData,
        faceData: val.faceData || {}
      }
    }
  })]

})
