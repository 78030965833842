import Vue from 'vue'

import {
  Button, Form, Field, CellGroup, Checkbox, CheckboxGroup, RadioGroup, Radio, DatetimePicker,
  NavBar, Tabbar, TabbarItem, Popup, Picker, Search, Dialog, Notify, Grid, GridItem, Loading,
  Icon, Tab, Tabs, DropdownMenu, DropdownItem, Toast, Image as VanImage, Sticky, Swipe, SwipeItem,
  SubmitBar, Card, SwipeCell, Stepper, Tag, AddressList, Cell, ActionSheet, Collapse, CollapseItem,
  Divider, PullRefresh, List, Overlay, NoticeBar, Uploader, AddressEdit, Area, Col, Row,
  Pagination, Switch, Badge, Step, Steps, Progress, Popover, CountDown, Sidebar, SidebarItem, Lazyload
} from 'vant'
// vant ui 组件注册
Vue.use(Button).use(Form).use(Field).use(CellGroup).use(Checkbox).use(CheckboxGroup).use(RadioGroup).use(Radio).use(DatetimePicker)
  .use(NavBar).use(Tabbar).use(TabbarItem).use(Popup).use(Picker).use(Search).use(Dialog).use(Notify).use(Grid).use(GridItem)
  .use(Loading).use(Icon).use(Tab).use(Tabs).use(DropdownMenu).use(DropdownItem).use(Toast).use(VanImage).use(Sticky).use(Swipe)
  .use(SwipeItem).use(SubmitBar).use(Card).use(SwipeCell).use(Stepper).use(Tag).use(AddressList).use(Cell).use(ActionSheet)
  .use(Collapse).use(CollapseItem).use(Divider).use(PullRefresh).use(List).use(Overlay).use(NoticeBar).use(Uploader)
  .use(AddressEdit).use(Area).use(Col).use(Row).use(Pagination).use(Switch).use(Badge).use(Step).use(Steps).use(Progress)
  .use(Popover).use(CountDown).use(Sidebar).use(SidebarItem).use(Lazyload)
