import Homelayout from '@/views/home' // 主页面结构
// import ReturnPage from '@/views/return-page' // 返回页面结构

export default [{
  path: '/journal',
  component: Homelayout,
  children: [
    {
      path: 'newcomer-log',
      name: 'newcomerLog',
      component: () => import('@/views/journal/newcomer-log.vue'),
      meta: { title: '部门日报' }
    },
    {
      path: 'daily',
      name: 'daily',
      component: () => import('@/views/journal/daily.vue'),
      meta: { title: '部门日报' }
    },
    {
      path: 'weekly',
      name: 'weekly',
      component: () => import('@/views/journal/weekly.vue'),
      meta: { title: '部门周报' }
    },
    {
      path: 'monthly-report',
      name: 'monthly-report',
      component: () => import('@/views/journal/monthly-report.vue'),
      meta: { title: '部门月报' }
    },
    {
      path: 'daily-report-in-charge',
      name: 'daily-report-in-charge',
      component: () => import('@/views/journal/daily-report-in-charge.vue'),
      meta: { title: '主管日志' }
    },
    {
      path: 'business-log',
      name: 'journal-business-log',
      component: () => import('@/views/distributor/business-log.vue'),
      meta: { title: '扶商日报' }
    },
    {
      path: 'menber-log-controller',
      name: 'menber-log-controller',
      component: () => import('@/views/journal/menber-log-controller.vue'),
      meta: { title: '集团日报' }
    }
  ]
}]
