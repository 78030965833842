import ReturnPage from '@/views/return-page' // 返回页面结构
import myservice from './return-page/my-service'
import system from './return-page/system'
import ihrm from './return-page/ihrm'
import customermanagement from './return-page/customer-management'
import salesorder from './return-page/sales-order'
import personalperformance from './return-page/personal-performance'
import customerpickup from './return-page/customer-pick-up'
import storemanagement from './return-page/store-management'
import homepage from './return-page/home-page'
import messagemanagement from './return-page/message-management'
import processSignOff from './return-page/processSignOff'
import companyHomepage from './return-page/company-homepage'
import newCompanyHomepage from './return-page/new-company-homepage'
import administrativemanagement from './return-page/administrative-management'
import distributor from './return-page/distributor'
import journal from './return-page/journal'
import financialmanagement from './return-page/financial-management'
import newdistributor from './return-page/new-distributor'
import purchaseOrder from './return-page/purchase-order'
import managementDecision from './return-page/management-decision'

export default [{
  path: '/return-page',
  name: 'return-page',
  component: ReturnPage,
  children: [
    ...myservice, // 进货预结算
    ...system, // 系统管理
    ...ihrm, // 人事管理
    ...customermanagement, // 客户管理
    ...salesorder, // 销售订单
    ...personalperformance, // 个人业绩
    ...customerpickup, // 客户提货
    ...storemanagement, // 门店管理
    ...homepage, // 首页
    ...messagemanagement, // 消息管理
    ...administrativemanagement, // 行政管理
    ...distributor, // 招商采购
    ...journal, // 成长日志
    ...financialmanagement, // 财务
    ...managementDecision, // 管理决策
    ...newdistributor, // 新招商经销
    ...purchaseOrder // 进货下单
  ]
},
...processSignOff, // 流程签核
...companyHomepage, // 首页
...newCompanyHomepage // 新首页
]
