export default [
  {
    path: 'details-customer-decision/:id',
    name: 'details-customer-decision',
    component: () => import('@/views/management-decision/components/new-customer/details-customer-decision.vue'),
    meta: { title: '客户详情' },
    props: true
  },
  {
    path: 'details-development-analysis/:id/:date',
    name: 'details-development-analysis',
    component: () => import('@/views/management-decision/components/development-activities/development-analysis/details-development-analysis.vue'),
    meta: { title: '开发分析详情', rekeepAlive: true, scrollTop: 0, name: 'detailsDevelopmentAnalysis' },
    props: true
  }
]
