import Service from '@/utils/request.js'

// 门店标示红点
/**
 * 标示红点
 * @param {*} params
 * @returns orderCount : 订单管理的
 * @returns upCount      : 货物管理
 */
export const retailOrderManagerStoreMsgCount = (params) => Service.post('/retailOrderManager/storeMsgCount', params)
