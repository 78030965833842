const state = {
  faceData: {}
}
const mutations = {
  setFaceData (state, data) { // 存储临时对象数据 含多个对象
    state.faceData[data.name] = data
  },
  removeFaceData (state, data) { // 清除临时对象数据 含多个对象
    if (data) {
      if (data.name in state.faceData) {
        delete state.faceData[data.name]
      }
    } else {
      state.faceData = {}
    }
  }
}
const actions = {
  setFaceData (store, data) { // 存储临时数据 含多个对象
    store.commit('setFaceData', data)
  },
  removeFaceData (store, data) { // 清除临时数据 含多个对象
    store.commit('removeFaceData', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
