// 全局设置
const state = {
  GlobalSettings: {}
}
const mutations = {
  setGlobalSettings (state, data) {
    state.GlobalSettings[data.name] = data
  },
  removeGlobalSettings (state, data) {
    state.GlobalSettings[data.name] = data
  }
}
const actions = {
  setGlobalSettings (store, data) {
    store.commit('setGlobalSettings', data)
  },
  removeGlobalSettings (store, data) {
    store.commit('removeGlobalSettings', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
