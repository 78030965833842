<template>
  <div class="content" style="padding-bottom: 58px">
    <van-form @submit="onSubmit">
      <div class="el-block">
        <DatePicker
          class="search"
          :required="true"
          label="日期"
          placeholder="点击选择日期"
          :value="getDate(subData.dateId,1)"
          @onConfirm="onDatePicker"
          :rules="[{ required: true, message: '日期不能为空' }]"
        />
        <SearchPicker
          class="search"
          label="归属部门"
          placeholder="归属部门"
          :list="orgList"
          :value="orgName"
          :falg="typeof dataList == 'object' && type"
          displayValue="orgName"
          @onConfirm="onSearchCallback"
          :fn="getOrgList"
          :required="true"
          :rules="[{ required: true, message: '归属部门不能为空' }]"
        />
        <van-field
          class="input_req"
          v-model="subData.paymentManual"
          type="number"
          required
          label="营业额"
          :rules="[{ required: true, message: '营业额不能为空' }]"
        >
          <template #button>
            <span>元</span>
          </template>
        </van-field>
        <FooterFixed tlement="0px" :telescoping="false">
          <template #ex_footer>
            <div class="footer">
              <van-button block round class="bgBule" native-type="submit">确定</van-button>
            </div>
          </template>
        </FooterFixed>
      </div>
    </van-form>
  </div>
</template>

<script>
import { storePaymentManualAdd, storePaymentManualOrgList, storePaymentManualModify } from '@/api/store-management/performance-management'
import SearchPicker from '@/components/collectionPicker/SearchPicker.vue'
import DatePicker from '@/components/collectionPicker/DatePicker.vue'
import FooterFixed from '@/components/FooterFixed.vue'
import { getDate } from '@/utils'
export default {
  props: {
    list: { // 归属部门数据 首页进入
      type: Array,
      default: () => []
    },
    id: { // 门店进入 且是编辑
      type: String,
      default: ''
    },
    type: { // 判断是新增还是编辑
      type: Boolean,
      default: false
    },
    dataList: {
      default: null
    }
  },
  name: 'RegisteredTurnover',
  data () {
    return {
      orgList: [],
      orgName: '',
      subData: {
        orgId: '',
        manId: '',
        paymentManual: '',
        dateId: getDate(new Date())
      }
    }
  },
  watch: {
    dataList: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val) {
          this.orgName = val.orgName
          this.subData.orgId = val.orgId
          this.subData.manId = val.manId
          this.subData.paymentManual = val.paymentManual
          this.subData.dateId = val.dateId
        } else {
          Object.assign(this.$data, this.$options.data())
        }
      }
    }
  },
  methods: {
    getDate,
    async getOrgList (val) {
      if (!this.type) {
        if (!this.list) return
        if (val) {
          const text = val
          const reg = new RegExp(text)
          // 利用正则规则获取符合搜索条件的数据
          const list = this.list.filter(item => reg.test(item.orgName))
          // 获取符合条件的数据
          this.orgList = [...list]
        } else {
          // val为空，则恢复全部数据显示
          this.orgList = [...this.list]
        }
      } else {
        const { data: res } = await storePaymentManualOrgList({ id: this.subData.manId || '', key: val || '' })
        this.orgList = res
      }
    },
    onDatePicker (val) {
      this.subData.dateId = getDate(val)
    },
    onSearchCallback (val) {
      this.orgName = val.orgName
      this.subData.orgId = val.orgId
    },
    onSubmit () {
      if (!this.dataList) {
        this.$dialog.confirm({
          message: '确定登记？'
        }).then(() => {
          this.$store.commit('loading/showLoading', '登记中...')
          storePaymentManualAdd(this.subData).then(res => {
            if (res.code == 200) {
              this.$toast('登记成功')
              this.$emit('onConfim', true)
            }
          })
        }).catch(() => {})
      } else {
        this.$dialog.confirm({
          message: '确定编辑？'
        }).then(() => {
          this.$store.commit('loading/showLoading', '保存中...')
          storePaymentManualModify(this.subData).then(res => {
            if (res.code == 200) {
              this.$toast('保存成功')
              this.$emit('onConfim', true)
            }
          })
        }).catch(() => {})
      }
    }
  },
  components: {
    SearchPicker,
    DatePicker,
    FooterFixed
  }
}
</script>

<style lang="less" scoped>
.content {
  min-height: 400px;
}
</style>
