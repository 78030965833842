import Homelayout from '@/views/home' // 主页面结构
// import ReturnPage from '@/views/return-page' // 返回页面结构

export default [{
  path: '/ihrm',
  component: Homelayout,
  children: [
    {
      path: 'staff-list',
      name: 'staff-list',
      component: () => import('@/views/ihrm/staff-list.vue'),
      meta: { title: '在职员工', keepAlive: true, scrollTop: 0, name: 'staffList' }
    },
    {
      path: 'leave-management',
      name: 'leave-management',
      component: () => import('@/views/ihrm/leave-management.vue'),
      meta: { title: '离职名单', keepAlive: true, scrollTop: 0, name: 'leavemanagement' }
    },
    {
      path: 'punch-attendance',
      name: 'punch-attendance',
      component: () => import('@/views/ihrm/punch-attendance.vue'),
      meta: { title: '百度打卡' }
    },
    {
      path: 'gaode-punch',
      name: 'gaode-punch',
      component: () => import('@/views/ihrm/gaode-punch.vue'),
      meta: { title: '高德打卡' }
    },
    {
      path: 'attendance-record',
      name: 'attendance-record',
      component: () => import('@/views/ihrm/attendance-record.vue'),
      meta: { title: '考勤记录' }
    },
    {
      path: 'new-attendance-record',
      name: 'new-attendance-record',
      component: () => import('@/views/ihrm/new-attendance-record.vue'),
      meta: { title: '出勤记录' }
    },
    {
      path: 'Induction',
      name: 'Induction',
      component: () => import('@/views/ihrm/Induction.vue'),
      meta: { title: '入职审核' }
    },
    {
      path: 'organization',
      name: 'organization',
      component: () => import('@/views/ihrm/organization.vue'),
      meta: { title: '组织架构', keepAlive: true, scrollTop: 0, name: 'organization' }
    },
    {
      path: 'birthday-reminder',
      name: 'birthday-reminder',
      component: () => import('@/views/ihrm/birthday-reminder.vue'),
      meta: { title: '生日提醒' }
    },
    {
      path: 'new-punch-attendance',
      name: 'new-punch-attendance',
      component: () => import('@/views/ihrm/new-punch-attendance.vue'),
      meta: { title: '新百度打卡' }
    },
    {
      path: 'new-gaode-punch',
      name: 'new-gaode-punch',
      component: () => import('@/views/ihrm/new-gaode-punch.vue'),
      meta: { title: '新高德打卡' }
    },
    {
      path: 'title-management',
      name: 'title-management',
      component: () => import('@/views/ihrm/title-management.vue'),
      meta: { title: '职称管理' }
    }
  ]
}]
