import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueBus from 'vue-bus'
import '@/plugin/common' // 公用js 包含组件注册
import directives from '@/directives/index' // 全局自定义指令

import './style/reset.less' // 全局样式
import '@/style/quillEditor.css' // 富文本编辑器样式
import { filterPrivateUnits } from './utils/user-code'
Vue.config.productionTip = false

Vue.use(directives)
Vue.use(VueBus)
Vue.prototype.$axios = axios
Vue.prototype.$filterPrivateUnits = filterPrivateUnits

// 防止用户修改 localStorage 的值
// window.addEventListener('storage', function (e) {
//   localStorage.setItem(e.key, e.oldValue)
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
