import { msgMsgCount, msgFlowCount } from '@/api/message-management/message-list'
import { retailOrderManagerStoreMsgCount } from '@/api/store-management/red-dot-api'
import store from '@/store'

function upNum (name, val) { // 修改未读数量
  val > 99 && (val = `${99}+`)
  const obj = {}
  obj.name = name
  obj[name] = val
  store.dispatch('user/setUnreadNum', obj)
}

export async function getUnreadQuantity () {
  const { data: res } = await msgMsgCount() // 消息未读数
  const { data: res2 } = await msgFlowCount() // 签核数量
  const { data: res3 } = await retailOrderManagerStoreMsgCount() // 门店订单管理待审核,货物管理未确认
  upNum('purchaseMangerListCount', res3.purchaseMangerListCount)
  upNum('listNum', res)
  upNum('flowRemindCount', res2.flowRemindCount)
  upNum('notPayOrder', res2.notPayOrder)
  upNum('signing', res2.flowCount)
  upNum('tosign', res2.flowWaitCount)
  upNum('refused', res2.rejectCount)
  upNum('orderCount', res3.orderCount) // 订单
  upNum('upCount', res3.upCount) // 货物
}
export async function getNewsNum () { // 单个消息
  const { data: res } = await msgMsgCount() // 消息未读数
  upNum('listNum', res)
}
export async function getSignOffNum () { // 单个签核
  const { data: res2 } = await msgFlowCount() // 签核数量
  upNum('signing', res2.flowCount)
  upNum('tosign', res2.flowWaitCount)
  upNum('refused', res2.rejectCount)
  upNum('notPayOrder', res2.notPayOrder)
  upNum('flowRemindCount', res2.flowRemindCount)
}
export async function getStoreRedNum () { // 单个门店
  const { data: res2 } = await retailOrderManagerStoreMsgCount() // 订单管理待审核,货物管理未确认
  upNum('purchaseMangerListCount', res2.purchaseMangerListCount)
  upNum('orderCount', res2.orderCount) // 订单
  upNum('upCount', res2.upCount) // 货物
}
