export default [
  {
    path: 'customer-registration/:id',
    name: 'customer-registrationId',
    component: () => import('@/views/customer-management/customer-registration.vue'),
    meta: { title: '编辑客户信息' },
    props: true
  },
  {
    path: 'details-customer/:id',
    name: 'details-customer',
    component: () => import('@/views/customer-management/components/customer-list/details-customer.vue'),
    meta: { title: '客户详情' },
    props: true
  },
  {
    path: 'details-kh-customer-order/:id',
    name: 'details-kh-customer-order',
    component: () => import('@/views/customer-management/components/customer-list/order-list/details-customer-order.vue'),
    meta: { title: '订单详情' },
    props: true
  },
  {
    path: 'detalis-order-list/:id',
    name: 'detalis-order-list',
    component: () => import('@/views/customer-management/components/customer-list/order-list.vue'),
    meta: { title: '客户所有订单', rekeepAlive: true, scrollTop: 0, name: 'orderListKh' },
    props: true
  },
  {
    path: 'detalis-high-seas-customers/:id',
    name: 'detalis-high-seas-customers',
    component: () => import('@/views/customer-management/components/high-seas-customers/detalis-high-seas-customers.vue'),
    meta: { title: '公海客户详情' },
    props: true
  }
]
