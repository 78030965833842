import Homelayout from '@/views/home' // 主页面结构

export default [{
  path: '/new-distributor',
  component: Homelayout,
  children: [
    {
      path: 'dealer-management',
      name: 'dealer-management',
      component: () => import('@/views/new-distributor/dealer-management.vue'),
      meta: { title: '经销商管理', keepAlive: true, scrollTop: 0, name: 'dealerManagement' }
    },
    {
      path: 'collection-account',
      name: 'collection-account',
      component: () => import('@/views/new-distributor/collection-account.vue'),
      meta: { title: '收款账户', keepAlive: true, scrollTop: 0, name: 'collectionAccount' }
    },
    {
      path: 'picking-management',
      name: 'new-picking-management',
      component: () => import('@/views/new-distributor/picking-management.vue'),
      meta: { title: '货物管理', keepAlive: true, scrollTop: 0, name: 'newPickingManagement' }
    },
    {
      path: 'product-management',
      name: 'new-product-management',
      component: () => import('@/views/new-distributor/new-product-management.vue'),
      meta: { title: '产品管理', keepAlive: true, scrollTop: 0, name: 'newProductManagement' }
    },
    {
      path: 'advance-payment',
      name: 'new-dis-advance-payment',
      component: () => import('@/views/new-distributor/advance-payment.vue'),
      meta: { title: '预存货款', keepAlive: true, scrollTop: 0, name: 'NewDisAdvancePayment' }
    },
    {
      path: 'dealer-order',
      name: 'new-dealer-order',
      component: () => import('@/views/new-distributor/dealer-order.vue'),
      meta: { title: '经销商订单', keepAlive: true, scrollTop: 0, name: 'NewDealerOrder' }
    },
    {
      path: 'list-of-manufacturers',
      name: 'list-of-manufacturers',
      component: () => import('@/views/new-distributor/list-of-manufacturers.vue'),
      meta: { title: '厂商名单', keepAlive: true, scrollTop: 0, name: 'listOfManufacturers' }
    },
    {
      path: 'purchase-order',
      name: 'new-purchase-order',
      component: () => import('@/views/new-distributor/purchase-order.vue'),
      meta: { title: '采购订单', keepAlive: true, scrollTop: 0, name: 'NewPurchaseOrder' }
    },
    {
      path: 'new-purchase-order',
      name: 'new-purchase-order-two',
      component: () => import('@/views/new-distributor/new-purchase-order.vue'),
      meta: { title: '新采购订单', keepAlive: true, scrollTop: 0, name: 'NewPurchaseOrderTwo' }
    },
    {
      path: 'dealer-performance',
      name: 'new-dealer-performance',
      component: () => import('@/views/new-distributor/dealer-performance.vue'),
      meta: { title: '经销商业绩', keepAlive: true, scrollTop: 0, name: 'NewDealerPerformance' }
    },
    {
      path: 'employee-self-purchase',
      name: 'new-dis-employee-self-purchase',
      component: () => import('@/views/new-distributor/employee-self-purchase.vue'),
      meta: { title: '员工自购', keepAlive: true, scrollTop: 0, name: 'NewDisEmployeeSelfPurchase' }
    },
    {
      path: 'supporting-business-performance',
      name: 'supporting-business-performance',
      component: () => import('@/views/new-distributor/supporting-business-performance.vue'),
      meta: { title: '扶商业绩', keepAlive: true, scrollTop: 0, name: 'supportingBusinessPerformance' }
    },
    {
      path: 'yousheng-settlement',
      name: 'yousheng-settlement',
      component: () => import('@/views/new-distributor/yousheng-settlement.vue'),
      meta: { title: '优生活结算', keepAlive: true, scrollTop: 0, name: 'youshengSettlement' }
    },
    {
      path: 'gross-profit-analysis',
      name: 'gross-profit-analysis',
      component: () => import('@/views/new-distributor/gross-profit-analysis.vue'),
      meta: { title: '毛利分析', keepAlive: true, scrollTop: 0, name: 'grossProfitAnalysis' }
    }
  ]
}]
