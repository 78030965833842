<template>
  <div class="searchpicker">
    <div v-if="ChangeBtn" @click="onFalg">{{ChangeBtnName}}</div>
    <van-field
      v-show="!ChangeBtn"
      v-model="text"
      readonly
      :name="name"
      :required="required"
      :class="required?'input_req':''"
      :label="label"
      :disabled="falg"
      :placeholder="placeholder"
      @click="onFalg"
      :rules="rules"
      :input-align="align"
    />
    <Teleport :to="TeleportId" :disabled="!!TeleportId">
      <van-popup v-model="showPicker" position="bottom" class="popup"
      :close-on-click-overlay="closeOnClickOverlay">
        <van-search :placeholder="searchPlaceholder" :clearable="false" v-model="interpolateSearch" @input="onChange" v-if="searchShow"/>
        <div class="multiple" v-if="multiple">
          <div class="multiple_bolck" v-for="(item, index) in checkboxValue" :key="index+item">{{item}}</div>
        </div>
        <van-picker  v-if="!multiple" show-toolbar :columns="columns" :loading="loading"  @confirm="onConfirm" @change="handlerChange">
          <template #cancel>
            <slot name="cancel"><span @click="onCancel">{{ cancelText }}</span></slot>
          </template>
          <slot slot="title" name="title">
          </slot>
        </van-picker>
        <div v-else class="dh-field">
          <div class="loading" v-if="loading"><van-loading color="#1989fa" /></div>
          <div class="van-picker__toolbar">
            <button type="button" class="van-picker__cancel" @click="onEmpty">清空</button>
            <button type="button" class="van-picker__confirm" @click.stop="onConfirmBox">确认</button>
          </div>
          <div style="height:264px; overflow-y:auto;" >
            <van-checkbox-group ref="checkboxGroup" v-model="checkboxShowValue">
              <van-cell-group>
                <van-cell
                  v-for="(item, index) in columns"
                  :key="item[option.value]"
                  :title="item[option.label]"
                  clickable
                  @click="item.disabled ? '' : toggle(item, index)"
                >
                  <template #right-icon>
                    <van-checkbox :ref="'checkboxes'+item[option.value]" :name="item[option.value]" v-if="!item.disabled"/>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-checkbox-group>
          </div>
        </div>
      </van-popup>
    </Teleport>
  </div>
  <!-- :sared="funName" -->
</template>

<script>
import { isEmptyStr } from '@/utils/index'
import Teleport from '@/components/Teleport.vue'
import _ from 'lodash'
// let timers = null
export default {
  name: 'searchPicker',
  props: {
    value: {
      default: '',
      type: String
    },
    showValue: {
      default: '',
      type: String
    },
    closeOnClickOverlay: { // 是否在点击遮罩层后关闭
      type: Boolean,
      default: true
    },
    name: { // 文本框的 name
      type: String
    },
    valueKey: { // 是否默认搜索
      default: '',
      type: String
    },
    label: { // label 名称
      type: String,
      default: '选择器'
    },
    placeholder: { // 提示内容
      default: '点击选择',
      type: String
    },
    align: {
      default: 'left',
      type: String
    },
    required: { // 必填
      type: Boolean,
      default: false
    },
    rules: { // 效验规则
      type: Array,
      default: () => []
    },
    searchPlaceholder: {
      type: String,
      default: '请输入要搜索的内容'
    },
    searchNum: { // 输入多少个字符查处数据
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default: () => []
    },
    displayValue: {}, // 数据里需要显示的值属性
    cion: { // 数据里需要显示的值的间隔符号
      type: String,
      default: ' '
    },
    falg: { // 禁用
      type: Boolean,
      default: false
    },
    fn: { // 查询数据函数
      type: Function,
      default: function () {
        return Promise.resolve('')
      }
    },
    option: { // 变成多选时需要显示的值
      type: Object,
      default: function () {
        return { label: 'text', value: 'text' }
      }
    },
    emptyOption: { // 开启空选项
      type: Boolean,
      default: false
    },
    TeleportId: { // 传送元素id
      type: String,
      default: ''
    },
    ChangeBtn: {
      type: Boolean,
      default: false
    },
    ChangeBtnName: {
      type: String,
      default: ''
    },
    multiple: { // 是否开启多选
      default: false
    },
    searchShow: { // 是否隐藏搜索框 默认不隐藏
      default: true
    },
    cancelText: { // 取消按钮文本
      default: '取消'
    },
    twoData: { // 是否存在第二列
      default: null
    }
  },
  provide: function () {
    return {
      parent: this
    }
  },
  data () {
    return {
      showPicker: false,
      interpolateSearch: '', // 输入框数据
      text: '',
      markLoading: false,
      loading: false,
      checkboxValue: this.value ? this.value.split(',') : [], // 复选框显示的值
      checkboxShowValue: this.showValue ? this.showValue.split(',') : [], // 复选框内部显示的值
      checkedAll: false,
      columnsData: []
    }
  },
  computed: {
    funName () { // 监听输入几个字符
      if (!this.showPicker) return this.interpolateSearch
      this.onChange(this.interpolateSearch)
      return this.interpolateSearch
    },
    columns () { // 当数据不存在 text 显示值时
      let list = this.list
      if (list.length <= 0 && !this.emptyOption) {
        return [{ text: '查无数据', disabled: true }]
      }
      if (this.displayValue) {
        if (typeof this.displayValue == 'string') {
          list = list.map(item => {
            if (item[this.displayValue]) {
              item.text = item[this.displayValue]
            }
            return item
          })
        } else if (typeof this.displayValue == 'object' && this.displayValue.constructor == Array) {
          list = list.map(item => {
            let c = ''
            for (const i in this.displayValue) {
              if (item[this.displayValue[i]]) {
                c = c ? c + this.cion + item[this.displayValue[i]] : item[this.displayValue[i]]
              }
            }
            item.text = c
            return item
          })
        }
        if (this.emptyOption) { // 开启空选项
          list.unshift({ text: '' })
        }
        // 第二列的数据加进去
        if (!isEmptyStr(this.twoData)) {
          return [
            { values: list }, { values: this.twoData }
          ]
        }
        return list
      }
      if (this.emptyOption) { // 开启空选项
        list.unshift({ text: '' })
      }
      return list
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.text = val
      }
    },
    valueKey (val) {
      this.interpolateSearch = this.valueKey
    },
    showPicker (val) {
      if (!val) {
        this.markLoading = !isEmptyStr(this.interpolateSearch)
        if (isEmptyStr(this.valueKey)) {
          this.interpolateSearch = ''
        }
        this.$emit('onClose')
      }
    }
  },
  methods: {
    handlerChange (e, a, b) {
      // console.log(a, b)
      this.$emit('change', a, b)
    },
    onChange (val) {
      this.loading = true
      this.getFn(val)
    },
    getFn: _.debounce(function (val) {
      this.fn(val || '').then((res) => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
      this.$emit('onSearch', val)
    }, 300),
    onFalg () {
      if (this.falg) return
      this.showPicker = true
      this.loading = this.list.length <= 0 || this.markLoading
      this.getFn()
      this.$emit('handlerClick')
    },
    onCancel () { // 取消
      this.showPicker = false
      this.$emit('cancel')
    },
    onEmpty () { // 清空
      this.text = ''
      this.checkboxValue = []
      this.checkboxShowValue = []
      this.$emit('onEmpty', { text: this.checkboxValue, value: this.checkboxShowValue })
    },
    onConfirm (val) { // 确定
      if (val.disabled) return
      let falg = false
      this.$emit('onConfirm', val, (res = false) => {
        falg = res
      })
      this.showPicker = falg
    },
    onConfirmBox () { // 多选确定
      this.text = this.checkboxValue.join(',')
      this.$emit('onConfirm', { text: this.checkboxValue, value: this.checkboxShowValue })
      this.showPicker = false
      this.$emit('submitFn')
    },
    toggle (val, index) { // 多选
      this.$refs[`checkboxes${val[this.option.value]}`][0].toggle()
      if (this.$refs[`checkboxes${val[this.option.value]}`][0].checked) {
        this.checkboxValue = this.checkboxValue.filter(item => item != val[this.option.label])
      } else {
        this.checkboxValue.push(val[this.option.label])
      }
      setTimeout(() => {
        this.$emit('onConfirm', { text: this.checkboxValue, value: this.checkboxShowValue })
      })
    }
  },
  components: {
    Teleport
  }
}
</script>

<style lang="less" scoped>
// .underline{
//   position: relative;
// }
// .underline::after{
//   position: absolute;
//   box-sizing: border-box;
//   content: ' ';
//   pointer-events: none;
//   right: 0px;
//   bottom: 0;
//   left: 0px;
//   border-bottom: 1px solid #ebedf0;
//   -webkit-transform: scaleY(.5);
//   transform: scaleY(.5);
// }
// /deep/.van-field__body input{
//   text-align: left !important;
// }
.searchpicker{
  width: 100%;
}
.popup /deep/.van-search{
  padding: 10px !important;
}

.dh-field {
  padding: 0;
  background:#fff;
  position: relative;
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: #ffffff85;
  }
  .van-cell {
    padding: 10px 16px;
  }
  .van-cell--required::before {
    left: -8px;
  }
  .van-popup {
    border-radius: 20px 20px 0 0;
  }
}
.multiple {
  padding:  0 10px;
  line-height: 17px;
  max-height: 100px;
  overflow: hidden;
  overflow-y: auto;
  .multiple_bolck {
    border-radius: 5px;
    padding: 5px;
    font-size: 13px;
    background: #f3f3f3;
    color: #333;
    display: inline-block;
    margin: 0 5px 5px 0;
  }
}
</style>
