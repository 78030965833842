import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { getToken } from '@/utils/auth' // 利用cookies存取token
import { WXwhiteList } from './publicField'

//  axios创建对象
const Server = axios.create({
  baseURL: process.env.VUE_APP_INTERFACE_URL, // 管理后台要使用的接口的基地址
  timeout: 15 * 60000 // 超时时间
})
//  定义前置拦截器，请求拦截器，请求发送出去之前触发的
Server.interceptors.request.use((config) => {
  // config 接口请求的配置信息
  const time = Date.now
  if (store.getters.token) { // 将后端获取的token加到接口的请求头里去后端匹配
    const loginTime = localStorage.getItem('loginTime')
    const isTimeout = time - loginTime
    // 主动介入登入超时
    if (isTimeout > 21600000) {
      store.dispatch('user/cleanupData')
      router.push('/login')
      return Promise.reject(new Error('已登入超时'))
    }
    const toPageName = store.state.user.toPageName
    if (getToken() && !WXwhiteList.includes(toPageName)) {
      config.headers.Authorization = getToken()
    }
    if (config.method.toUpperCase() === 'POST') { // 防止发起请求时 Conter-Type 被修改
      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json'
      }
      if (config.headers['Content-Type'] !== 'application/json') {
        config.headers['Content-Type'] = 'application/json'
      }
    }
  }
  return config
}, (error) => {
  // 报错的时候抛出一个报错的信息
  console.log(error)
  return Promise.reject(error)
})

//  定义后置拦截器,响应拦截器, 服务器响应回来数据之前触发，
Server.interceptors.response.use((response) => {
  // 成功
  const { code, msg, success } = response.data
  store.commit('loading/hideLoading')
  if (code == 507) {
    Vue.prototype.$dialog.alert({
      title: '系统提示',
      message: msg
    }).then(() => {
      router.push('/homepage')
    }).catch(() => {
      router.push('/homepage')
    })
    return
  }
  if (code === 200 || success) {
    return response.data
  } else {
    if (response.config.method === 'get' && msg === '账号不存在！') {
      return response.data
    }
    if (code === 604 && msg === '用户名不存在') {
      Vue.prototype.$dialog.alert({
        title: '系统提示',
        message: msg
      })
      return Promise.reject(response)
    }
    if (code == 604) {
      // store.dispatch('user/cleanupData')// 清理vuex中的数据
      Vue.prototype.$dialog.alert({
        title: '系统提示',
        message: msg
      }).then(res => {
        if (msg == '请先登录!') {
          store.commit('user/removeToken')// 清理vuex中的数据
          // store.dispatch('user/cleanupData')// 清理vuex中的数据
          router.push('/login')
          location.reload()
        }
      })
    } else if (code == 707) {
      store.dispatch('user/setPasswordStatus', true)
      setTimeout(() => {
        Vue.prototype.$dialog.alert({
          title: '系统提示',
          message: msg
        })
      }, 500)
    } else {
      Vue.prototype.$dialog.alert({
        title: '系统提示',
        message: msg
      })
    }
    return Promise.reject(response)
  }
  // 响应回来的数据操作
  // return response.data
}, (error) => {
  console.log(error)
  store.commit('loading/hideLoading')
  // 报错的是时候抛出一个报错的信息
  if (error.code == 'ERR_NETWORK') {
    Vue.prototype.$dialog.alert({
      title: '系统提示',
      message: '网络延迟，请刷新页面'
    })
  } else if (error.message == 'timeout of 60000ms exceeded') {
    Vue.prototype.$dialog.alert({
      title: '系统提示',
      message: '网络延迟，请刷新页面'
    })
  } else if (error.response) {
    if (error.response.data.code === 604) {
      Vue.prototype.$dialog.alert({
        title: '系统提示',
        message: error.response.data.msg
      }).then(() => {
        store.dispatch('user/cleanupData')// 清理vuex中的数据
        // error.config.headers.Authorization = ''
        router.push('/login')
        location.reload()
      })
      return Promise.reject(error)
    }
    if ((error.response.data.msg === '登录已失效!' || error.response.data.msg === '用户不存在!')) {
      Vue.prototype.$dialog.alert({
        title: '系统提示',
        message: error.response.data.msg
      }).then(() => {
        store.dispatch('user/cleanupData')// 清理vuex中的数据
        // error.config.headers.Authorization = ''
        router.push('/login')
        location.reload()
      })
    } else {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(error.response.data.msg)) {
        Vue.prototype.$dialog.alert({
          title: '系统提示',
          message: '网络延迟，请刷新页面'
        })
      } else {
        Vue.prototype.$dialog.alert({
          title: '系统提示',
          message: error.response.data.msg
        })
      }
    }
  }
  return Promise.reject(error)
})

//  抛出对象的信息
export default Server
