import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { delImg, checkUpFile, dateVerification } from './components'
import { getPermisCode } from './permissionCode'
import { anchorLink } from './animation'
import exportList from './exportList'
import { getToken } from '@/utils/auth' // 利用cookies存取token
/**
 * Created by PanJiaChen on 16/11/18.
 * 时间格式
 * @param { string | date } dateTiem 时间字符串 或者 new Date()
 * @param { string | number } v 想要显示的时间格式
 * @param { boolean } info 把时间格式去掉特殊符号显示
 */
export function getDate (dateTiem, v, info = false, AddDayCount) {
  if (!dateTiem) return ''
  let date = new Date()
  if (dateTiem) {
    if (typeof dateTiem == 'string' && dateTiem.indexOf('-') != -1) {
      // 兼容ios
      dateTiem = dateTiem.replace(/-/g, '/')
      if (dateTiem.split('/').length == 2) {
        dateTiem = dateTiem + '/01'
      } else if (dateTiem.split('/').length <= 1) {
        dateTiem = dateTiem + '/01/01'
      }
    }
    date = new Date(dateTiem)
  }
  const Y = date.getFullYear()
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
  const D = (date.getDate() >= 10 ? date.getDate() : '0' + date.getDate())
  const h = (date.getHours() >= 10 ? date.getHours() : '0' + date.getHours())
  const m = (date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes())
  const s = (date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds())
  let time = `${Y}-${M}-${D} ${h}:${m}:${s}`
  if (v == 1) {
    time = `${Y}-${M}-${D}`
  } else if (v == 2) {
    time = `${Y}-${M}-${D} 00:00:00`
  } else if (v == 'm') {
    time = `${Y}-${M}-${D} ${h}:${m}`
  } else if (v == 'time') {
    time = `${h}:${m}:${s}`
  } else if (v == 'hm') {
    time = `${h}:${m}`
  } else if (v == 'month') {
    time = `${Y}-${M}`
  } else if (v == 'y') {
    time = `${Y}`
  } else if (v == 'md') {
    time = `${M}-${D}`
  } else if (v == 'M') {
    time = `${M}`
  }
  if (info) {
    time = time.split(' ').join('').split(':').join('').split('-').join('')
  }
  return (time + '').trim()
}

/**
 * Created by PanJiaChen on 16/11/18.
 * 原始时间格式
 * @param { string } dateTiem 时间字符串
 */
export function getOriginalDate (dateTiem) {
  if (!dateTiem) return ''
  let data = dateTiem
  if (typeof data == 'string' && data.indexOf('-') != -1) {
    // 兼容ios
    data = data.replace(/-/g, '/')
    if (data.split('/').length == 2) {
      data = data + '/' + '01'
    } else if (data.split('/').length == 1) {
      data = data + '/' + '01' + '/' + '01'
    }
  }
  return new Date(data)
}

/**
 * 获取指定天
 * @param { number, string } AddDayCount 时间字符串
 * @param { string } dateTiem 指定日期 必须包含年月日
 */
export function getDateStr (AddDayCount, dateTiem) {
  let date = new Date()
  if (dateTiem) {
    if (typeof dateTiem == 'string' && dateTiem.indexOf('-') != -1) {
      // 兼容ios
      dateTiem = dateTiem.replace(/-/g, '/')
    }
    date = new Date(dateTiem)
  }
  date.setDate(date.getDate() + AddDayCount)// 获取AddDayCount天后的日期
  const Y = date.getFullYear()
  const M = date.getMonth() + 1// 获取当前月份的日期
  const D = date.getDate()
  const time = `${Y}-${M}-${D}`
  return time
}

/**
 * 获取当前年当前月 或 当前月的以下月份 或 上个月 的 某一天
 * @param { Number, String } v -1 上个月 0 当月 1 下个月 2 下下个月 以此类推
 * @param { Number, String } d  z 最后一天， 哪一天 01 02
 */
export function getTimeSot (v, d) {
  const i = isEmptyStr(v) ? 0 : (v - 0)
  const date = new Date()
  const Y = date.getFullYear()
  const M = (date.getMonth() + 1 + i < 10
    ? '0' + (date.getMonth() + 1 + i)
    : date.getMonth() + 1 + i >= 12
      ? 12
      : date.getMonth() + 1 + i)
  const z = isEmptyStr(d)
    ? '01'
    : d == 'z'
      ? new Date(Y, M, 0).getDate()
      : (d - 0) >= new Date(Y, M, 0).getDate()
          ? new Date(Y, M, 0).getDate()
          : d
  const D = z
  const time = `${Y}-${M}-${D}`
  return (time + '').trim()
}

/**
 * 获取当前，年or月(前or后)几年or几月的时间
 * @param { Number } num 前 or 后 几年
 * @param { Number, String } around 1 before 前 or 2 after 后
 * @param { Date } tirm 指定日期
 * @param { string } YorM 操作月还是年 Y or M 为 M 时 num 最大只能为11
 * @param { string, Number } day null 默认, 01 一个月第一天
 */
export function getYearsAround (num, around, YorM = 'Y', day = null, tirm = new Date()) {
  if (Math.abs(num) > 12 && YorM == 'M') return
  const date = tirm
  let Y = date.getFullYear()
  let M = date.getMonth() + 1
  let D = (date.getDate() >= 10 ? date.getDate() : '0' + date.getDate())
  if (around == 1 || around == 'before') {
    if (YorM == 'Y') {
      Y = Y - num
    } else if (YorM == 'M') {
      const decline = M - num
      if (decline > 0) {
        M = decline
      } else { // 减的月份大于当前月份， 则 年减一年
        M = 12 - Math.abs(decline)
        Y = Y - 1
      }
    }
  } else if (around == 2 || around == 'after') {
    if (YorM == 'Y') {
      Y = Y + num
    } else if (YorM == 'M') {
      const rise = M + num
      if (rise <= 12) {
        M = rise
      } else { // 加的月份大于当前月份， 则 年加一年
        M = rise - 12
        Y = Y + 1
      }
    }
  }
  M = (M < 10 ? '0' + (M) : M)
  if (YorM == 'M') { // 为月份时重新计算修改过得月份的最后一天或当前天
    D = (new Date(Y, M, 0).getDate() >= 10 ? new Date(Y, M, 0).getDate() : '0' + new Date(Y, M, 0).getDate())
  }
  if (D >= (day - 0) && !isEmptyStr(day)) D = day >= 10 ? day : '0' + day
  return `${Y}-${M}-${D}`
}

/**
 * 计算两个时间段相差多少天
 */
export function getDaysBetween (dateString1, dateString2) {
  const startDate = Date.parse(dateString1)
  const endDate = Date.parse(dateString2)
  const days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000)
  return days.toFixed(1)
}

// 将(秒)转化为时分秒的形式
export function getTime (value) {
  let secondTime = parseInt(value) // 秒
  let minuteTime = 0 // 分
  let hourTime = 0 // 时
  if (secondTime > 60) {
    // 如果秒数大于60，将秒数转换成整数
    // 获取分钟，除以60取整，得到整数分钟
    minuteTime = parseInt(secondTime / 60)
    // 获取秒数，秒数取余，得到整数秒数
    secondTime = parseInt(secondTime % 60)
    // 如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      // 获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60)
      // 获取小时后取余的分，获取分钟除以60取余的分
      minuteTime = parseInt(minuteTime % 60)
    }
  }
  // 若秒数是个位数，前面用0补齐
  secondTime = secondTime < 10 ? '0' + secondTime : secondTime
  let result = '' + secondTime + ''
  if (minuteTime > 0) {
    // 若分钟数是个位数，前面用0补齐
    minuteTime = minuteTime < 10 ? '0' + minuteTime : minuteTime
    result = '' + minuteTime + ':' + result
  } else {
    // 若分钟数为0，用"00"表示
    result = '' + '00' + ':' + result
  }

  if (hourTime > 0) {
    // 若小时数是个位数，前面用0补齐
    hourTime = hourTime < 10 ? '0' + hourTime : hourTime
    result = '' + hourTime + ':' + result
  } else {
    // 若小时数为0，用"00"表示
    result = '' + '00' + ':' + result
  }
  return result
}

export function getTimeTwo (time) {
  const t = time.split(':')
  if (t[0] == 'undefined') return '--:--'
  for (const i in t) {
    if (t[i] < 10) {
      t[i] = '0' + t[i]
    }
  }
  return t.join(':')
}

// 不是图片文件修改显示图片
/**
 *
 * @param { 文件路径 } i
 * @returns
 */
export function imgTypeFn (i) {
  if (isEmptyStr(i)) return null // 加载问题导致i为传值
  const info = ['jpg', 'jpeg', 'png', 'webp', 'JPEG', 'PNG', 'JPG']
  if (!i.includes('.')) { // 没有点的话那就是坏文件， 随便链接个点让他正常
    i = i + '.' + 'jpg'
  } else if (!(/^[a-zA-Z]+$/g.test(i.split('.')[i.split('.').length - 1]))) { // 如果有点，但是不是后缀名
    i = i + '.' + 'jpg'
  }
  let h = i.split('.')
  h = h[h.length - 1].toLowerCase() // 转小写
  if (info.includes(h)) {
    return i
  }
  if (h === 'pdf') {
    return require('../assets/download/file_pdf.png')
  } else if (h === 'txt') {
    return require('../assets/download/file_txt.png')
  } else if (h === 'doc' || h === 'docx') {
    return require('../assets/download/file_doc.png')
  } else if (h === 'xls' || h === 'xlsx') {
    return require('../assets/download/file_xls.png')
  } else if (h === 'ppt' || h === 'pptx') {
    return require('../assets/download/file_ppt.png')
  } else {
    return require('../assets/download/file_other.png')
  }
}

// 下载文件
/**
 *
 * @param { 文件路径 } i
 * @param { 下载下来文件叫的名称 } n
 */
export function openFileFn (i, n) {
  let h = i.split('.')
  h = h[h.length - 1]
  let name
  if (n) {
    name = n + '.' + h
  } else {
    name = '其它附件.' + h
  }
  store.commit('loading/showLoading', '请求文件下载中...')
  axios({
    url: process.env.VUE_APP_INTERFACE_URL + '/file/downloadFile',
    responseType: 'blob',
    method: 'get',
    params: { path: i, name },
    headers: { Authorization: getToken() },
    timeout: 60000 * 3
  }).then(res => {
    store.commit('loading/hideLoading')
    const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=UTF-8' }) // 构造一个blob对象来处理数据，并设置文件类型
    const href = window.URL.createObjectURL(blob) // 创建新的URL表示指定的blob对象
    const a = document.createElement('a') // 创建a标签
    a.style.display = 'none'
    a.href = href // 指定下载链接
    a.download = name// 指定下载文件名
    a.click() // 触发下载
    window.URL.revokeObjectURL(a.href) // 释放URL对象
    Vue.prototype.$toast('请求文件成功，等待下载')
  })
}

/**
 *  导出，存在兼容问题 目前发现uc浏览器无法导出
 * @param { json } data // 数据
 * @param { string } n // 文件名称
 * @param { string } suffix // 文件后缀
 * @param { string } url // 接口路径
 */
export function downloadFiles (data, n, suffix = 'xls', url) {
  let h, name
  if (!(data instanceof Object)) {
    h = data.split('.')
    h = h[h.length - 1]
  } else {
    h = suffix || 'xls'
  }
  if (n) {
    name = n + '.' + h
  } else {
    name = '其它附件.' + h
  }
  store.commit('loading/showLoading', '请求文件下载中...')
  return axios({
    url: process.env.VUE_APP_INTERFACE_URL + url,
    responseType: 'blob',
    method: 'post',
    data,
    headers: { Authorization: getToken },
    timeout: 60000 * 3
  }).then(res => {
    store.commit('loading/hideLoading')
    if (!res || res.data.size == 0) {
      Vue.prototype.$dialog({
        message: `近期没有${n}，不用下载`
      })
      return res
    }
    const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=UTF-8' }) // 构造一个blob对象来处理数据，并设置文件类型
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob)
    } else {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      elink.download = name
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL对象
    }
    // const href = window.URL.createObjectURL(blob) // 创建新的URL表示指定的blob对象
    // const a = document.createElement('a') // 创建a标签
    // a.style.display = 'none'
    // a.href = href // 指定下载链接
    // a.download = name // 指定下载文件名
    // a.click() // 触发下载
    // window.URL.revokeObjectURL(a.href) // 释放URL对象
    Vue.prototype.$toast('请求文件成功，等待下载')
    return res
  }).catch(err => {
    Vue.prototype.$toast('请求文件失败')
    console.log(err)
    return err
  })
}

// 回到页面回到原先浏览位置，清空位置
/**
 *
 * @param { vue 实例 } vm
 * @param { 去到的页面，回到的页面 } to
 * @param { 要回到浏览位置且 ‘存在垂直滚动条’ 元素id } id
 * @param { boolean } isWin 操作id 还是操作网页可视
 */
export function beforBackToPage (vm, to, id = '#main', isWin) {
  // 回到原来的位置
  vm.$nextTick(() => {
    const position = to.meta.scrollTop
    // if (!isWin) {
    //   document.querySelector(id).scrollTop = position || 0
    // } else {
    //   document.documentElement.scrollTop = position || 0
    // }
    document.documentElement.scrollTop = position || 0
  })
}

/**
 *
 * @param { 前往的页面，即将要去到页面 } to
 * @param { 当前页面name } from
 * @param { 要回到浏览位置且 ‘存在垂直滚动条’ 元素id } id
 * @param { Array } pageName 前往页面的 [name] 值
 * @param { boolean } whoPage 是否为返回页
 * @param { boolean } isWin 操作id 还是操作网页可视
 */
// 离开页面缓存当前页保存浏览位置
export function beforJumpOutPage (to, from, pageName = [], id = '#main', whoPage, isWin) {
  // 保存离开页面时的位置
  if (pageName.includes(to.name)) {
    const position = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
    from.meta.scrollTop = position
  } else if (!pageName.includes(to.name) && !from.meta.long && !whoPage) {
    // console.log('sss')
    store.dispatch('dynamicCache/removeKeepAlive', from.meta.name)
  } else if (!pageName.includes(to.name) && whoPage) {
    store.dispatch('dynamicCache/removeReturnKeepAlive', from.meta.name)
  }
}

/**
 * 判断是否为空字符串
 * @param {*} val
 */
export function isEmptyStr (val) {
  if (val == null || val === '' || val == undefined || (val + '') == 'null' || (val + '') == 'undefined') {
    return true
  } else if (JSON.stringify(val) === '[]' || JSON.stringify(val) === '{}') {
    return true
  }
  return false
}

/**
 * 加法运算
 * @param { String || Number } arg1
 * @param { String || Number } arg2
 * @returns
 */
export function floatAdd (arg1, arg2) {
  let r1, r2, m
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  // eslint-disable-next-line prefer-const
  m = Math.pow(10, Math.max(r1, r2))
  return Number((arg1 * m + arg2 * m) / m)
}

/**
 * 减法运算
 * @param { String || Number } arg1 减数
 * @param { String || Number } arg2 被减数
 * @returns
 */
export function floatSub (arg1, arg2) {
  let r1, r2, m, n
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  // eslint-disable-next-line prefer-const
  m = Math.pow(10, Math.max(r1, r2))
  // 动态控制精度长度
  // eslint-disable-next-line prefer-const
  n = (r1 >= r2) ? r1 : r2
  return Number(((arg1 * m - arg2 * m) / m).toFixed(n))
}

/**
* 乘法运算，避免数据相乘小数点后产生多位数和计算精度损失。
*
* @param num1被乘数 | num2乘数
*/
export function numMulti (num1, num2) {
  let baseNum = 0
  try { baseNum += num1.toString().split('.')[1].length } catch (e) {}
  try { baseNum += num2.toString().split('.')[1].length } catch (e) {}
  return Number(num1.toString().replace('.', '')) * Number(num2.toString().replace('.', '')) / Math.pow(10, baseNum)
}

/**
 * 除法运算，避免数据相除小数点后产生多位数和计算精度损失。
 *
 * @param num1被除数 | num2除数
 */
export function numDiv (num1, num2) {
  let baseNum1 = 0; let baseNum2 = 0
  let baseNum3, baseNum4
  try {
    baseNum1 = num1.toString().split('.')[1].length
  } catch (e) {
    baseNum1 = 0
  }
  try {
    baseNum2 = num2.toString().split('.')[1].length
  } catch (e) {
    baseNum2 = 0
  }
  // eslint-disable-next-line prefer-const
  baseNum3 = Number(num1.toString().replace('.', ''))
  // eslint-disable-next-line prefer-const
  baseNum4 = Number(num2.toString().replace('.', ''))
  return (baseNum3 / baseNum4) * Math.pow(10, baseNum2 - baseNum1)
}

/**
 * 防抖函数
 * @param {*} fn 需要防抖的函数
 * @param {*} delay 秒
 * @returns
 */
export function debounce (fn, delay) {
  let timeout = null
  return function (...ages) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(this, ...ages)
    }, delay)
  }
}

/**
 * 金额转换 转成万
 * @param {*} num 金额
 * @returns
 */
export function formatNumber (num) {
  num = Number(num)
  if (num >= 0 && num < 10000) {
    return num + ''
  } else if (num < 0) {
    const num2 = formatNumber(Math.abs(num))
    return `-${num2}`
  } else {
    return (num / 10000).toFixed(2) + '万'
  }
}

export { delImg, checkUpFile, getPermisCode, dateVerification, exportList, anchorLink }

/**
 * 校验商品的数量
 */
export function productNumFn (obj, obj1) {
  const restNum = (+obj1.goodsAmountBig) * obj1.smallUnitCount + obj1.goodsAmountSmall
  const num = (+obj.goodsAmountBig) * obj.smallUnitCount + obj.goodsAmountSmall
  return restNum < num
}

/**
 * 设置滚动条位置 ios系统适配
 */
export function setScrollPosition (left, top) {
  if (!checkIsIOS()) {
    return
  }
  // if (typeof window.scrollTo === 'function') {
  window.scrollTo(left, top)
  // }
  // if (typeof document.documentElement.scrollTop === 'number' && typeof document.documentElement.scrollLeft == 'number') {
  //   document.documentElement.scrollTop = top
  //   document.documentElement.scrollLeft = left
  // } else {
  document.body.scrollTop = top
  document.body.scrollLeft = left
  // }
}

/**
 * 深拷贝
 */
export const parseFn = (o) => JSON.parse(JSON.stringify(o))

/**
 * 检查是不是苹果系统
 */
export function checkIsIOS () {
  return /ios|iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase())
}
