const state = {
  LOADING: false,
  value: '',
  info: false
}
const mutations = {
  // logInfo 只会是 true
  showLoading (state, data) {
    if (state.LOADING) return // 如果一个 loading 还没执行完，依旧是 true 时，那就不需要重新赋值 且 修改提示内容
    state.LOADING = true
    if (typeof (data) == 'object') { // 需要特定的清除遮罩层时
      state.value = data.name
      state.info = data.logInfo || false
    } else {
      state.value = data
    }
  },
  hideLoading (state, data) {
    if (data && state.info) {
      state.LOADING = false
      state.value = ''
      state.info = false
    } else if (!data && !state.info) {
      state.LOADING = false
      state.value = ''
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
