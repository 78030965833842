<template>
  <div id="return-page">
    <!-- 头部 -->
    <van-nav-bar ref="page" left-text="返回" fixed left-arrow @click-left="onClickLeft">
      <template #title>
        <div id="return_title">
          {{ title }}
        </div>
      </template>
      <template #right>
        <div id="Teleport_page">
        </div>
      </template>
    </van-nav-bar>
    <!-- 主内容区 -->
    <div class="main scroll_hide" id="returnMain">
      <keep-alive :include="clacInclude">
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/api/user'
import { beforBackToPage } from '@/utils'
export default {
  name: 'returnpage',
  data () {
    return {
      title: '',
      urlName: '',
      flag: true,
      returnUrl: ['processSignOff', 'return-page', 'ScanCodePage', 'return-company-homepage']
    }
  },
  created () {
    this.getPageLoadForTheFirstTime()
    this.address()
    this.$store.dispatch('dynamicCache/setKeepAliveFrame', 'returnpage') // 进入页面缓存当前页
    if (sessionStorage.getItem('reloaded') == 'true') {
      // console.log('页面被刷新')
      this.getGetUserInfo()
    } else {
    // 这里可以写调用什么方法
      sessionStorage.setItem('reloaded', true)
    }
  },
  computed: {
    clacInclude () {
      return this.$store.state.dynamicCache.returnPage
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.title) {
        this.title = to.meta.title
      }
      if (!to.meta.scrollTop) {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 0
        })
      }
      // this.$store.dispatch('order/removeOrder') // 清除购物车结算订单
      this.$store.dispatch('order/removePayment') // 清除付款数据
    }
  },
  methods: {
    async getGetUserInfo () {
      try {
        this.$store.commit('loading/showLoading', { name: '加载中, 请稍后...', logInfo: true })
        const { data: res } = await getUserInfo()
        this.$store.dispatch('user/setUserInfo', res)
        this.$store.commit('loading/hideLoading', true)
      } catch (err) {
        this.$store.commit('loading/hideLoading', true)
      }
    },
    onClickLeft () { // 返回上一页
      this.$router.back()
    },
    address () { // 地址修改标签
      if (this.$route.name === 'edit-address' && !this.$route.params.id) {
        this.title = '新增地址'
      } else {
        this.title = this.$route.meta.title
      }
    },
    advance () { // 预存款修改标签
      if (this.$route.name === 'edit-advance' && !this.$route.params.id) {
        this.title = '新增预存款'
      } else {
        this.title = this.$route.meta.title
      }
    },
    getPageLoadForTheFirstTime () { // 首次加载时不允许直接进入返回页, 如果直接进入返回页，回到首页
      if (window.name == '') {
        // console.log('首次加载')
        window.name = 'isReload'
        if (this.returnUrl.includes(this.$router.history.current.matched[0].name)) {
          this.$router.push('/')
        }
      } else if (window.name == 'isReload') {
        // console.log('页面被刷新')
      }
    }
  },
  destroyed () {
    sessionStorage.setItem('reloaded', false)
  },
  // 动态缓存页面实现详情页回到原先页面回到原先浏览位置
  beforeRouteEnter (to, from, next) {
    // console.log(from.name + 'from')
    next(vm => {
      beforBackToPage(vm, to, '#returnMain')
    })
  },
  // 离开页面
  beforeRouteLeave (to, from, next) {
    const arr = to.fullPath.split('/')
    const html = [
      'ScanCodePage',
      'NotFound'
    ]
    // console.log(to.name + 'to')
    // 保存离开页面时的位置
    if (!arr.includes('return-page') && !html.includes(to.name)) {
      this.$store.dispatch('dynamicCache/removeKeepAliveFrame', 'returnpage')
    }
    next()
  }
}
</script>

<style lang="less" scoped>
  .main{
    padding: 46px 0 5px;
    // overflow-y: auto;
    box-sizing: border-box;
    // background: #fff;
    -ms-overflow-style:none;
    scrollbar-width:none;
    &::-webkit-scrollbar{
      display:none;
    }
    &>div{
      min-height: 100%;
    }
  }
  /deep/.van-nav-bar--fixed{
    z-index: 2;
  }
</style>
