export default [
  // 个人业绩
  {
    path: 'my-salary/details-employee-salaries/:id',
    name: 'my-salary-details-employee-salaries',
    component: () => import('@/views/personal-performance/components/employee-salaries/details-employee-salaries.vue'),
    meta: { title: '工资详情' },
    props: true
  },
  {
    path: 'details-historical-performance/:id',
    name: 'details-historical-performance',
    component: () => import('@/views/personal-performance/components/historical-performance/details-historical-performance.vue'),
    meta: { title: '业绩详情' },
    props: true
  },
  {
    path: 'details-performance-examine/:id',
    name: 'details-performance-examine',
    component: () => import('@/views/personal-performance/components/historical-performance/details-performance-examine.vue'),
    meta: { title: '业绩审核详情' },
    props: true
  },
  {
    path: 'edit-performance-examine/:id',
    name: 'edit-performance-examine',
    component: () => import('@/views/personal-performance/components/historical-performance/edit-performance-examine.vue'),
    meta: { title: '业绩审核编辑' },
    props: true
  },
  // // 我的借支
  // // // 借支
  {
    path: 'details-my-debit/:id/:type',
    name: 'details-my-debit',
    component: () => import('@/views/personal-performance/components/my-debit/borrowing-list/details-my-debit.vue'),
    meta: { title: '借支详情' },
    props: true
  },
  // // // 代付
  {
    path: 'details-my-payment-by-the-company/:id',
    name: 'details-my-payment-by-the-company',
    component: () => import('@/views/personal-performance/components/my-debit/payment-by-the-company/details-payment-by-the-company.vue'),
    meta: { title: '代付详情' },
    props: true
  },
  {
    path: 'details-my-bonus/:id',
    name: 'details-my-bonus',
    component: () => import('@/views/personal-performance/components/my-bonus/details-my-bonus.vue'),
    meta: { title: '奖金详情' },
    props: true
  },
  // // 招商业绩
  {
    path: 'details-achiecementsYear/:id/:type?',
    name: 'details-achiecementsYear',
    component: () => import('@/views/personal-performance/components/investment-performance/achievementsYear/details-achiecementsYear.vue'),
    meta: { title: '业绩详情' },
    props: true
  },
  {
    path: 'individual-details-dealer-performance/:id',
    name: 'individual-details-dealer-performance',
    component: () => import('@/views/personal-performance/components/investment-performance/achievementsYear/details-dealer-performance.vue'),
    meta: { title: '经销商业绩详情', rekeepAlive: true, scrollTop: 0, name: 'individualDetailsDealerPerformance' },
    props: true
  }
]
