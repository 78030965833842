import Service from '@/utils/request.js'

/**
 * 业绩删除
 * @param {*} params
 * @returns
 */
export const achievementDelete = (params) => Service.post('/orgAchievement/achievementDelete', params)
/**
 * 历史业绩
 * @param {*} params
 * @returns
 */
export const achievementList = (params) => Service.post('/orgAchievement/achievementList', params)
/**
 * 业绩详情
 * @param {*} params
 * @returns
 */
export const orgAchievementDetail = (params) => Service.post('/orgAchievement/detail', params)
/**
 * 财务审核
 * @param {*} params
 * @returns
 */
export const finance = (params) => Service.post('/orgAchievement/finance', params)
/**
 * 收款账户列表
 * @param {*} params
 * @returns
 */
export const orgAchievementList = (params) => Service.post('/orgAchievement/list', params)
/**
 * 订单详情
 * @param {*} params
 * @returns
 */
export const orderDetail = (params) => Service.post('/orgAchievement/orderDetail', params)
/**
 * 业绩排名
 * @param {*} params
 * @returns
 */
export const achievementRank = (params) => Service.post('/orgAchievement/achievementRank', params)
/**
 * 获取业绩类型
 * @param {*} params
 * @returns
 */
export const getTypeList = (params) => Service.post('/orgAchievement/getTypeList', params)
/**
 * 修改业绩类型
 * @param {*} params
 * @returns
 */
export const modifyCommis = (params) => Service.post('/orgAchievement/modifyCommis', params)
/**
 * 部门下拉框
 * @param {*} params
 * @returns
 */
export const selectTag = (params) => Service.post('/orgAchievement/selectTag', params)

// --------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------
// 业绩审核
/**
 * 业绩删除
 * @param {*} params
 * @returns
 */
export const storeAchievementAchievementDelete = (params) => Service.post('/storeAchievement/achievementDelete', params)
/**
 * 历史业绩
 * @param {*} params
 * @returns
 */
export const storeAchievementAchievementList = (params) => Service.post('/storeAchievement/achievementList', params)
/**
 * 业绩详情
 * @param {*} params
 * @returns
 */
export const storeAchievementDetail = (params) => Service.post('/storeAchievement/detail', params)
/**
 * 财务审核
 * @param {*} params
 * @returns
 */
export const storeAchievementFinance = (params) => Service.post('/storeAchievement/finance', params)
/**
 * 获取业绩类型
 * @param {*} params
 * @returns
 */
export const storeAchievementGetTypeList = (params) => Service.post('/storeAchievement/getTypeList', params)
/**
 * 收款账户列表
 * @param {*} params
 * @returns
 */
export const storeAchievementList = (params) => Service.post('/storeAchievement/list', params)
/**
 * 修改业绩类型
 * @param {*} params
 * @returns
 */
export const storeAchievementModifyCommis = (params) => Service.post('/storeAchievement/modifyCommis', params)
/**
 * 订单详情
 * @param {*} params
 * @returns
 */
export const storeAchievementOrderDetail = (params) => Service.post('/storeAchievement/orderDetail', params)
/**
 * 财务拒绝
 * @param {*} params
 * @returns
 */
export const storeAchievementReject = (params) => Service.post('/storeAchievement/reject', params)

// --------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------
/**
 * 退货记录
 * @param {*} params
 * @returns
 */
export const orgAchievementAchievementReturn = (params) => Service.post('/orgAchievement/achievementReturn', params)
/**
 * 退货记录修改
 * @param {*} params
 * @returns
 */
export const orgAchievementReturnModify = (params) => Service.post('/orgAchievement/returnModify', params)
/**
 * 用户列表
 * @param {*} params
 * @returns
 */
export const orgAchievementUserList = (params) => Service.post('/orgAchievement/userList', params)
/**
 * 用户所在部门下拉框
 * @param {*} params
 * @returns
 */
export const orgAchievementUserOrgList = (params) => Service.post('/orgAchievement/userOrgList', params)

// --------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------
// 当日业绩
/**
 * 当日度业绩
 * @param {*} params
 * @returns
 */
export const orgAchievementAgentAchievementDayListOrg = (params) => Service.post('/orgAchievement/agentAchievementDayListOrg', params)

// --------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------
// 店长业绩上报
/**
 * 登记营业额
 * @param {*} params
 * @returns
 */
export const storePaymentManualAdd = (params) => Service.post('/storePaymentManual/add', params)
/**
 * 删除营业额
 * @param {*} params
 * @returns
 */
export const storePaymentManualDeleted = (params) => Service.post('/storePaymentManual/deleted', params)
/**
 * 营业额列表
 * @param {*} params
 * @returns
 */
export const storePaymentManualList = (params) => Service.post('/storePaymentManual/list', params)
/**
 * 修改营业额
 * @param {*} params
 * @returns
 */
export const storePaymentManualModify = (params) => Service.post('/storePaymentManual/modify', params)
/**
 * 部门下拉
 * @param {*} params
 * @returns
 */
export const storePaymentManualOrgList = (params) => Service.post('/storePaymentManual/orgList', params)

// --------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------
// 提货业绩排名
/**
 * 提货排名
 * @param {*} params
 * @returns
 */
export const orgAchievementUpRank = (params) => Service.post('/orgAchievement/upRank', params)
// --------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------
// 年度排名

/**
 * 门店年度排名
 * @param {*} params
 * @returns
 */
export const yearPaymentOrgYear = (params) => Service.post('/yearPayment/orgYear', params)
/**
 * 门店用户年度排名
 * @param {*} params
 * @returns
 */
export const yearPaymentUserYear = (params) => Service.post('/yearPayment/userYear', params)
/**
 * 部门下拉框
 * @param {*} params
 * @returns
 */
export const yearPaymentSelectTag = (params) => Service.post('/yearPayment/selectTag', params)
