export default [
  {
    path: 'details-article-borrowing/:id/:signId',
    name: 'details-article-borrowing',
    component: () => import('@/views/administrative-management/details-article-borrowing.vue'),
    meta: { title: '借用详情' },
    props: true
  },
  {
    path: 'details-outreach-personnel-list/:id',
    name: 'details-outreach-personnel-list',
    component: () => import('@/views/administrative-management/components/outreach-personnel/personnel-list/details-outreach-personnel-list.vue'),
    meta: { title: '人员详情' },
    props: true
  },
  {
    path: 'edit-outreach-personnel-list/:id?',
    name: 'edit-outreach-personnel-list',
    component: () => import('@/views/administrative-management/components/outreach-personnel/personnel-list/edit-outreach-personnel-list.vue'),
    meta: { title: '人员编辑/新增' },
    props: true
  },
  {
    path: 'details-document-seal/:id/:signId',
    name: 'details-document-seal',
    component: () => import('@/views/administrative-management/components/document-seal/details-document-seal.vue'),
    meta: { title: '详情' },
    props: true
  }
]
