import Homelayout from '@/views/home' // 主页面结构

export default [{
  path: '/store-management',
  component: Homelayout,
  children: [
    {
      path: 'individual-retail',
      name: 'individual-retail',
      component: () => import('@/views/store-management/individual-retail.vue'),
      meta: { title: '超市零售', keepAlive: true, scrollTop: 0, name: 'individualRetail' }
    },
    {
      path: 'storefront-shares',
      name: 'storefront-shares',
      component: () => import('@/views/store-management/storefront-shares.vue'),
      meta: { title: '店面股份' }
    },
    {
      path: 'lease-contract',
      name: 'lease-contract',
      component: () => import('@/views/store-management/lease-contract.vue'),
      meta: { title: '租赁合同', keepAlive: true, scrollTop: 0, name: 'leaseContract' }
    },
    {
      path: 'asset-registration',
      name: 'asset-registration',
      component: () => import('@/views/store-management/asset-registration.vue'),
      meta: { title: '资产登记', keepAlive: true, scrollTop: 0, name: 'assetRegistration' }
    },
    {
      path: 'customer-management',
      name: 'customer-management',
      component: () => import('@/views/store-management/customer-management.vue'),
      meta: { title: '客户管理', keepAlive: true, scrollTop: 0, name: 'customerManagement' }
    },
    {
      path: 'customer-inventory',
      name: 'customer-inventory',
      component: () => import('@/views/store-management/customer-inventory.vue'),
      meta: { title: '客户存货', keepAlive: true, scrollTop: 0, name: 'customerInventory' }
    },
    {
      path: 'order-management',
      name: 'order-management',
      component: () => import('@/views/store-management/order-management.vue'),
      meta: { title: '订单管理', keepAlive: true, scrollTop: 0, name: 'orderManagement' }
    },
    {
      path: 'performance-management',
      name: 'performance-management',
      component: () => import('@/views/store-management/performance-management.vue'),
      meta: { title: '业绩管理', keepAlive: true, scrollTop: 0, name: 'performanceManagement' }
    },
    {
      path: 'picking-management',
      name: 'picking-management',
      component: () => import('@/views/store-management/picking-management.vue'),
      meta: { title: '货物管理', keepAlive: true, scrollTop: 0, name: 'pickingManagement' }
    },
    {
      path: 'warehouse-management',
      name: 'warehouse-management',
      component: () => import('@/views/store-management/warehouse-management.vue'),
      meta: { title: '仓库管理', keepAlive: true, scrollTop: 0, name: 'warehouseManagement' }
    },
    {
      path: 'cashier-account',
      name: 'cashier-account',
      component: () => import('@/views/store-management/cashier-account.vue'),
      meta: { title: '结算账户', keepAlive: true, scrollTop: 0, name: 'cashierAccount' }
    },
    {
      path: 'product-management',
      name: 'product-management',
      component: () => import('@/views/store-management/product-management.vue'),
      meta: { title: '产品管理', keepAlive: true, scrollTop: 0, name: 'productManagement' }
    },
    {
      path: 'borrowing-of-resale',
      name: 'borrowing-of-resale',
      component: () => import('@/views/store-management/borrowing-of-resale.vue'),
      meta: { title: '借出/转卖', keepAlive: true, scrollTop: 0, name: 'borrowingOfResale' }
    },
    {
      path: 'inventory-risk',
      name: 'inventory-risk',
      component: () => import('@/views/store-management/inventory-risk.vue'),
      meta: { title: '存货风险', keepAlive: true, scrollTop: 0, name: 'inventoryRisk' }
    }
  ]
}]
