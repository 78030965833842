import Cookies from 'vue-cookies'

const TokenKey = 'token'

export function getToken () {
  return Cookies.get(TokenKey) // 获取
}

export function setToken (token) {
  return Cookies.set(TokenKey, token, '0')// 设置
}

export function removeToken () {
  return Cookies.remove(TokenKey)// 删除
}
