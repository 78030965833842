export default [
  {
    path: 'add-vocher',
    name: 'add-vocher',
    component: () => import('@/views/store-management/components/product-management/voucher-list/add-vocher.vue'),
    meta: { title: '新增客户代金券' },
    props: true
  },
  {
    path: 'store-performance-settings/:id',
    name: 'storePerformanceSettings',
    component: () => import('@/views/store-management/components/customer-management/store-performance-settings.vue'),
    meta: { title: '客户业绩设置' },
    props: true
  },
  {
    path: 'details-lease-contract',
    name: 'details-lease-contract',
    component: () => import('@/views/store-management/components/lease-contract/details-lease-contract.vue'),
    meta: { title: '合同详情' },
    props: true
  },
  {
    path: 'quick-warehousing',
    name: 'quick-warehousing',
    component: () => import('@/views/store-management/components/product-management/quick-warehousing.vue'),
    meta: { title: '快速入库', keepAlive: true, scrollTop: 0, name: 'quickWarehousing' }
  },
  {
    path: 'edit-lease-contract',
    name: 'edit-lease-contract',
    component: () => import('@/views/store-management/components/lease-contract/edit-lease-contract.vue'),
    meta: { title: '编辑合同' },
    props: true
  },
  // 资产登记
  {
    path: 'details-asset-registration',
    name: 'details-asset-registration',
    component: () => import('@/views/store-management/components/asset-registration/details-asset-registration.vue'),
    meta: { title: '资产详情' },
    props: true
  },
  {
    path: 'details-flow-asset-registration/:id/:signId',
    name: 'details-flow-asset-registration',
    component: () => import('@/views/store-management/components/asset-registration/details-flow-asset-registration.vue'),
    meta: { title: '资产变卖详情' },
    props: true
  },
  {
    path: 'new-batch-sale-of-assets-detail/:id/:signId',
    name: 'new-batch-sale-of-assets-detail',
    component: () => import('@/views/store-management/components/asset-registration/new-batch-sale-of-assets-detail.vue'),
    meta: { title: '资产变卖(批量)详情' },
    props: true
  },
  {
    path: 'edit-asset-registration',
    name: 'edit-asset-registration',
    component: () => import('@/views/store-management/components/asset-registration/edit-asset-registration.vue'),
    meta: { title: '编辑资产' },
    props: true
  },
  // 客户管理
  {
    path: 'store-details-customer/:id',
    name: 'store-details-customer',
    component: () => import('@/views/store-management/components/customer-management/store-details-customer.vue'),
    meta: { title: '客户详情' },
    props: true
  },
  {
    path: 'store-edit-customer/:id',
    name: 'store-edit-customer',
    component: () => import('@/views/store-management/components/customer-management/store-edit-customer.vue'),
    meta: { title: '编辑客户' },
    props: true
  },
  {
    path: 'details-latest-comments/:id',
    name: 'details-latest-comments',
    component: () => import('@/views/store-management/components/customer-management/details-latest-comments.vue'),
    meta: { title: '客户详情' },
    props: true
  },
  {
    path: 'details-man-customer-order/:id/:type?',
    name: 'details-man-customer-order',
    component: () => import('@/views/store-management/components/order-management/details-customer-order.vue'),
    meta: { title: '订单详情', rekeepAlive: true, scrollTop: 0, name: 'detailsManCustomerOrder' }, // 门店 客户订单详情
    props: true
  },
  {
    path: 'details-voucher-order/:id/:order',
    name: 'details-voucher-order',
    component: () => import('@/views/store-management/components/order-management/details-voucher-order.vue'),
    meta: { title: '详情', rekeepAlive: true, scrollTop: 0, name: 'detailsVoucherOrder' }, // 点击订单，代金券详情
    props: true
  },
  {
    path: 'details-exchange-goods/:id',
    name: 'details-exchange-goods',
    component: () => import('@/views/store-management/components/order-management/details-exchange-goods.vue'),
    meta: { title: '换货' },
    props: true
  },
  {
    path: 'details-man-individual-customer-order/:id',
    name: 'details-man-individual-customer-order',
    component: () => import('@/views/store-management/components/order-management/details-individual-customer-order.vue'),
    meta: { title: '订单详情' }, // 门店 散客订单详情
    props: true
  },
  {
    path: 'details-performance-management/:id',
    name: 'details-performance-management',
    component: () => import('@/views/store-management/components/performance-management/details-performance-management.vue'),
    meta: { title: '业绩详情' },
    props: true
  },
  {
    path: 'details-performance-examine-management/:id',
    name: 'details-performance-examine-management',
    component: () => import('@/views/store-management/components/performance-management/details-performance-examine.vue'),
    meta: { title: '业绩审核详情', rekeepAlive: true, scrollTop: 0, name: 'detailsPerformanceExamineManagement' },
    props: true
  },
  {
    path: 'details-risk-not-raised/:id/:dateId?',
    name: 'details-risk-not-raised',
    component: () => import('@/views/store-management/components/performance-management/performance-examine/details-risk-not-raised.vue'),
    meta: { title: '客户存货详情' },
    props: true
  },
  {
    path: 'details-man-picking-record/:id/:bathId',
    name: 'details-man-picking-record',
    component: () => import('@/views/store-management/components/picking-management/details-man-picking-record.vue'),
    meta: { title: '详情' }, // 门店 提货记录详情
    props: true
  },
  {
    path: 'details-man-individual-record/:bathId/:id?',
    name: 'details-man-individual-record',
    component: () => import('@/views/store-management/components/picking-management/details-man-individual-record.vue'),
    meta: { title: '详情' }, // 门店 散客 提货记录详情
    props: true
  },
  {
    path: 'details-man-stock-record/:id/:bathId',
    name: 'details-man-stock-record',
    component: () => import('@/views/store-management/components/picking-management/details-man-stock-record.vue'),
    meta: { title: '详情' }, // 门店 存货记录详情
    props: true
  },
  {
    path: 'add-stock-out',
    name: 'add-stock-out',
    component: () => import('@/views/store-management/components/picking-management/add-stock-out.vue'),
    meta: { title: '新增出入库' },
    props: true
  },
  {
    path: 'details-man-warehousing-record/:id/:type',
    name: 'details-man-warehousing-record',
    component: () => import('@/views/store-management/components/picking-management/details-man-warehousing-record.vue'),
    meta: { title: '详情' }, // 货物管理 出入库记录详情
    props: true
  },
  {
    path: 'edit-man-warehousing-record/:id/:type',
    name: 'edit-man-warehousing-record',
    component: () => import('@/views/store-management/components/picking-management/edit-man-warehousing-record.vue'),
    meta: { title: '编辑' }, // 货物管理 出入库记录编辑提货客户图片
    props: true
  },
  {
    path: 'edit-warehouse-management/:id?',
    name: 'edit-warehouse-management',
    component: () => import('@/views/store-management/components/warehouse-management/edit-warehouse-management.vue'),
    meta: { title: '编辑' }, // 仓库编辑或新增
    props: true
  },
  {
    path: 'details-warehouse-management/:id',
    name: 'details-warehouse-management',
    component: () => import('@/views/store-management/components/warehouse-management/details-warehouse-management.vue'),
    meta: { title: '仓库详情' },
    props: true
  },
  {
    path: 'details-account-list/:id/:type',
    name: 'details-account-list',
    component: () => import('@/views/store-management/components/cashier-account/details-account-list.vue'),
    meta: { title: '账户详情' },
    props: true
  },
  {
    path: 'edit-account-list/:id?',
    name: 'edit-account-list',
    component: () => import('@/views/store-management/components/cashier-account/edit-account-list.vue'),
    meta: { title: '编辑' }, // 账户列表编辑
    props: true
  },
  {
    path: 'edit-account-flow/:id?',
    name: 'edit-account-flow',
    component: () => import('@/views/store-management/components/cashier-account/edit-account-flow.vue'),
    meta: { title: '编辑' }, // 账户流水编辑
    props: true
  },
  {
    path: 'details-account-flow/:id/:type',
    name: 'details-account-flow',
    component: () => import('@/views/store-management/components/cashier-account/account-flow/details-account-flow.vue'),
    meta: { title: '流水详情' }, // 账户流水详情
    props: true
  },
  // 产品管理
  {
    path: 'details-goods-Order/:who/:id',
    name: 'details-goods-Order',
    component: () => import('@/views/store-management/components/product-management/details-goods-order.vue'),
    meta: { title: '产品详情' }, // 产品管理-> 集团产品详情
    props: true
  },
  {
    path: 'details-store-prroducts/:id',
    name: 'details-store-prroducts',
    component: () => import('@/views/store-management/components/product-management/details-store-prroducts.vue'),
    meta: { title: '详情' }, // 产品管理-> 门店详情
    props: true
  },
  {
    path: 'edit-store-prroducts/:id?',
    name: 'edit-store-prroducts',
    component: () => import('@/views/store-management/components/product-management/edit-store-prroducts.vue'),
    meta: { title: '编辑' }, // 产品管理-> 门店编辑 / 新增
    props: true
  },
  {
    path: 'warehouse-store/:id?',
    name: 'warehouse-store',
    component: () => import('@/views/store-management/components/warehouse-management/warehouse-store.vue'),
    meta: { title: '库存详请' },
    props: true
  },
  // // 代金券
  {
    path: 'edit-voucher/:id?',
    name: 'edit-voucher',
    component: () => import('@/views/store-management/components/product-management/voucher-list/edit-voucher.vue'),
    meta: { title: '新建/编辑' },
    props: true
  },
  {
    path: 'details-voucher/:id/:type',
    name: 'details-voucher',
    component: () => import('@/views/store-management/components/product-management/voucher-list/details-voucher.vue'),
    meta: { title: '详情', rekeepAlive: true, scrollTop: 0, name: 'detailsVoucher' },
    props: true
  },
  // 借货转卖
  {
    path: 'details-lending-warehouse/:id',
    name: 'details-lending-warehouse',
    component: () => import('@/views/store-management/components/borrowing-of-resale/lending-record/details-lending-warehouse.vue'),
    meta: { title: '借出仓库' },
    props: true
  },
  {
    path: 'details-borrowed-warehouse/:id',
    name: 'details-borrowed-warehouse',
    component: () => import('@/views/store-management/components/borrowing-of-resale/borrowing-record/details-borrowed-warehouse.vue'),
    meta: { title: '借入仓库' },
    props: true
  },
  {
    path: 'details-resale-finance/:id',
    name: 'details-resale-finance',
    component: () => import('@/views/store-management/components/borrowing-of-resale/resale-record/details-resale-finance.vue'),
    meta: { title: '转卖财务' },
    props: true
  },
  {
    path: 'details-resale-warehouse/:id',
    name: 'details-resale-warehouse',
    component: () => import('@/views/store-management/components/borrowing-of-resale/resale-record/details-resale-warehouse.vue'),
    meta: { title: '转卖仓库' },
    props: true
  },
  {
    path: 'details-transfer-finance/:id',
    name: 'details-transfer-finance',
    component: () => import('@/views/store-management/components/borrowing-of-resale/transfer-record/details-transfer-finance.vue'),
    meta: { title: '受让财务' },
    props: true
  },
  {
    path: 'details-transfer-warehouse/:id',
    name: 'details-transfer-warehouse',
    component: () => import('@/views/store-management/components/borrowing-of-resale/transfer-record/details-transfer-warehouse.vue'),
    meta: { title: '受让仓库' },
    props: true
  },
  // 存货风险
  // // 存货风险
  {
    path: 'details-inventory-risk/:id/:date/:start/:end',
    name: 'details-inventory-risk',
    component: () => import('@/views/store-management/components/inventory-risk/inventory-risk/details-inventory-risk.vue'),
    meta: { title: '风险详细', rekeepAlive: true, scrollTop: 0, name: 'storeDetailsInventoryRisk' }, // 风险详细
    props: true
  },
  {
    path: 'details-inventory-products/:id/:date/:end?/:start?',
    name: 'details-inventory-products',
    component: () => import('@/views/store-management/components/inventory-risk/inventory-risk/details-inventory-products.vue'),
    meta: { title: '存货产品明细' },
    props: true
  },
  {
    path: 'details-risk-order/:id',
    name: 'details-risk-order',
    component: () => import('@/views/store-management/components/inventory-risk/inventory-risk/details-risk-order.vue'),
    meta: { title: '订单详情' }, // 风险订单详情
    props: true
  },
  // 货物管理
  // // 调货成本
  {
    path: 'details-transfer-cost/:id',
    name: 'details-transfer-cost',
    component: () => import('@/views/store-management/components/picking-management/transfer-cost/details-transfer-cost.vue'),
    meta: { title: '明细' },
    props: true
  },
  {
    path: 'details-man-purchase-list/:id',
    name: 'details-man-purchase-list',
    component: () => import('@/views/store-management/components/picking-management/man-purchase-list/details-man-purchase-list.vue'),
    meta: { title: '详情' },
    props: true
  },
  {
    path: 'delivery-details-inventory-products/:id/:date/:goodsId',
    name: 'delivery-details-inventory-products',
    component: () => import('@/views/store-management/components/inventory-risk/inventory-risk/details-inventory-products.vue'),
    meta: { title: '存货产品明细' },
    props: true
  }
]
