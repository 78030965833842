/**
 * @param { string } el 需要定位的元素id
 * @param { string } totalHeightDome 最大容量的元素
 * @param { string | Number } offset 定位之后的偏移量
 * @param { string | Number } setTime 需要几秒完成动画
 * @param { string } animationClass 定位之后添加的class
 */

let dome = null
export function anchorLink (el, offset = 0, animationClass = 'pti_animation') {
  if (dome && el) {
    dome.classList.remove(animationClass)
  } else if (dome && !el) {
    dome.classList.remove(animationClass)
    return
  } else if (!dome && !el) {
    return
  }
  const tragetElem = document.querySelector(el)
  const tragetElemPostition = tragetElem.offsetTop - offset

  // 判断是否支持新特性
  if (
    typeof window.getComputedStyle(document.body).scrollBehavior ==
    'undefined'
  ) {
  // 当前滚动高度
    let scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
    // 滚动step方法
    const step = function () {
    // 距离目标滚动距离
      const distance = tragetElemPostition - scrollTop

      // 目标需要滚动的距离，也就是只走全部距离的五分之一
      scrollTop = scrollTop + distance / 5
      if (Math.abs(distance) < 1) {
        window.scrollTo(0, tragetElemPostition)
        tragetElem.classList.add(animationClass)
        dome = tragetElem
      } else {
        window.scrollTo(0, scrollTop)
        setTimeout(step, 20)
      }
    }
    step()
  } else {
    tragetElem.scrollIntoView({
      behavior: 'smooth',
      inline: 'nearest',
      block: 'center'
    })
    tragetElem.classList.add(animationClass)
    dome = tragetElem
  }
}
