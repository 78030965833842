import { getDate } from '@/utils'
/**
 * 一些组件的回调
 */

/**
 * OriginalDrawingImg 组件， 组件为多个图片时的删除回调
 * @param { Array } data 原先数据
 * @param { Object } val 删除的图片
 * @returns
 */
export function delImg (data, val) {
  return data.filter(item => {
    if (val.url2) {
      return item != val.url2
    } else {
      return item != val.url
    }
  })
}

/**
 * 使用 form 表单时 当图片还在上传时，必填项是不会触发的
 * 此方法校验图片还在 上传时 且 该项存在必填时调用
 * @param { Array || String } val 校验的值
 * @returns
 */
export function checkUpFile (val) {
  if (Array.isArray(val) && val.length) {
    const status = val.some(item => { // 过滤还在上传中的文件
      if (item.status == 'uploading' || item.status == 'failed') {
        return true
      }
      return false
    })
    return !status
  }
}

/**
 * 时间校验大小
 * @param { String } val 校验的值 开始时间
 * @param { String } val2 校验的值 结束时间
 * @param { String || Number  } type 1 or 2
 * @returns
 */
export function dateVerification (val, val2, type) {
  if (val && val2) {
    return getDate(val) < getDate(val2)
  } else {
    return true
  }
}
