import Homelayout from '@/views/home' // 主页面结构
// import ReturnPage from '@/views/return-page' // 返回页面结构

export default [{
  path: '/sales-order',
  component: Homelayout,
  children: [
    {
      path: 'order-registration',
      name: 'order-registration',
      component: () => import('@/views/sales-order/order-registration.vue'),
      meta: { title: '订单登记', keepAlive: true, scrollTop: 0, name: 'orderRegistration' }
    },
    {
      path: 'order-list',
      name: 'order-list',
      component: () => import('@/views/sales-order/order-list.vue'),
      meta: { title: '订单列表', keepAlive: true, scrollTop: 0, name: 'orderList' }
    },
    {
      path: 'individual-retail',
      name: 'sales-individual-retail',
      component: () => import('@/views/store-management/individual-retail.vue'),
      meta: { title: '散客零售' }
    },
    {
      path: 'employee-self-purchase',
      name: 'employee-self-purchase',
      component: () => import('@/views/sales-order/employee-self-purchase.vue'),
      meta: { title: '员工自购', keepAlive: true, scrollTop: 0, name: 'employeeSelfPurchase' }
    },
    {
      path: 'customer-recharge',
      name: 'customer-recharge',
      component: () => import('@/views/sales-order/customer-recharge.vue'),
      meta: { title: '客户充值', keepAlive: true, scrollTop: 0, name: 'customerRecharge' }
    },
    {
      path: 'deduction-purchase',
      name: 'deduction-purchase',
      component: () => import('@/views/sales-order/deduction-purchase.vue'),
      meta: { title: '抵扣购买', keepAlive: true, scrollTop: 0, name: 'deductionPurchase' }
    }
  ]
}]
