export default [// // 新采购订单
  {
    path: 'details-new-two-purchase-finance/:id',
    name: 'details-new-two-purchase-finance',
    component: () => import('@/views/new-distributor/components/new-purchase-order/purchaseList/details-purchase-finance.vue'),
    meta: { title: '财务详情' },
    props: true
  },
  {
    path: 'details-new-two-purchase-warehouse/:id',
    name: 'details-new-two-purchase-warehouse',
    component: () => import('@/views/new-distributor/components/new-purchase-order/purchaseList/details-purchase-warehouse.vue'),
    meta: { title: '仓库详情' },
    props: true
  },
  {
    path: 'new-two-set-order',
    name: 'new-two-set-order',
    component: () => import('@/views/new-distributor/components/new-purchase-order/new-procurement/set-order.vue'),
    meta: { title: '采购清单', rekeepAlive: true, scrollTop: 0, name: 'newTwoSetOrder' },
    props: true
  },
  {
    path: 'details-new-two-consolidated-payment/:id',
    name: 'details-new-two-consolidated-payment',
    component: () => import('@/views/new-distributor/components/new-purchase-order/consolidated-payment/details-consolidated-payment.vue'),
    meta: { title: '合并支付详情' },
    props: true
  },
  {
    path: 'details-new-two-warehouse-dispatch/:id/:storeId',
    name: 'details-new-two-warehouse-dispatch',
    component: () => import('@/views/new-distributor/components/new-purchase-order/warehouse-dispatch/details-warehouse-dispatch.vue'),
    meta: { title: '仓库派单详情' },
    props: true
  },
  {
    path: 'edit-new-two-warehouse-dispatch/:id/:storeId',
    name: 'edit-new-two-warehouse-dispatch',
    component: () => import('@/views/new-distributor/components/new-purchase-order/warehouse-dispatch/edit-warehouse-dispatch.vue'),
    meta: { title: '仓库派单编辑' },
    props: true
  },
  {
    path: 'new-two-place-order/:id?/:storeId?',
    name: 'new-two-place-order',
    component: () => import('@/views/new-distributor/components/new-purchase-order/warehouse-dispatch/place-order.vue'),
    meta: { title: '采购清单' },
    props: true
  },
  {
    path: 'off-dateils-payment-process/:id/:signId',
    name: 'off-dateils-payment-process',
    component: () => import('@/views/new-distributor/components/new-purchase-order/purchaseList/off-dateils-payment-process.vue'),
    meta: { title: '采购支付流程' },
    props: true
  }
]
