import XLSX from 'xlsx'
// 导出 xlsx
// const common = {
//   install: function ($Vue) {
//     const Vue = $Vue.prototype
const exportList = {
  // 导出excel表格
  // data：导出参数，name：表格名称，cols： 每列宽度
  exportExcel (data, name, cols = []) {
    const sheet = XLSX.utils.aoa_to_sheet(data)
    sheet['!cols'] = cols
    this.downExcel(this.sheetBlob(sheet), name)
  },
  downExcel (url, saveName) {
    if (typeof url === 'object' && url instanceof Blob) {
      url = URL.createObjectURL(url) // 创建blob地址
    }
    const aLink = document.createElement('a')
    aLink.href = url
    aLink.download = saveName || '' // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
    let event
    if (window.MouseEvent) event = new MouseEvent('click')
    else {
      event = document.createEvent('MouseEvents')
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    }
    aLink.dispatchEvent(event)
  },
  // 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
  sheetBlob (sheet, sheetName) {
    sheetName = sheetName || 'sheet1'
    const workbook = {
      SheetNames: [sheetName],
      Sheets: {}
    }
    workbook.Sheets[sheetName] = sheet
    // 生成excel的配置项
    const wopts = {
      bookType: 'xlsx', // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: 'binary'
    }
    const wbout = XLSX.write(workbook, wopts)
    const blob = new Blob([s2ab(wbout)], {
      type: 'application/octet-stream'
    })
    // 字符串转ArrayBuffer
    function s2ab (s) {
      if (typeof ArrayBuffer !== 'undefined') {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
        return buf
      } else {
        const buf = new Array(s.length)
        for (let i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
        return buf
      }
    }
    return blob
  }
}
// 将公共方法复制到原型上
//     Vue.$common = common
//   }
// }

export default exportList
