import Homelayout from '@/views/home' // 主页面结构

export default [{
  path: '/personal-performance',
  component: Homelayout,
  children: [
    {
      path: 'performance-registration',
      name: 'performance-registration',
      component: () => import('@/views/personal-performance/performance-registration.vue'),
      meta: { title: '业绩登记' }
    },
    {
      path: 'historical-performance',
      name: 'historical-performance',
      component: () => import('@/views/personal-performance/historical-performance.vue'),
      meta: { title: '历史业绩', keepAlive: true, scrollTop: 0, name: 'historicalPerformance' }
    },
    {
      path: 'my-debit',
      name: 'my-debit',
      component: () => import('@/views/personal-performance/my-debit.vue'),
      meta: { title: '我的借支', keepAlive: true, scrollTop: 0, name: 'myDebit' }
    },
    {
      path: 'my-dividend',
      name: 'my-dividend',
      component: () => import('@/views/personal-performance/my-dividend.vue'),
      meta: { title: '我的分红', keepAlive: true, scrollTop: 0, name: 'myDividend' }
    },
    {
      path: 'my-bonus',
      name: 'my-bonus',
      component: () => import('@/views/personal-performance/my-bonus.vue'),
      meta: { title: '我的奖金', keepAlive: true, scrollTop: 0, name: 'myBonus' }
    },
    {
      path: 'investment-performance',
      name: 'investment-performance',
      component: () => import('@/views/personal-performance/investment-performance.vue'),
      meta: { title: '招商业绩', keepAlive: true, scrollTop: 0, name: 'investmentPerformance' }
    },
    {
      path: 'my-salary',
      name: 'my-salary',
      component: () => import('@/views/personal-performance/my-salary.vue'),
      meta: { title: '我的工资', keepAlive: true, scrollTop: 0, name: 'mySalary' }
    }
  ]
}]
