const mutations = {
  setTemporaryData (state, data) { // 存储临时数据
    state.temporaryData = data
  },
  removeTemporaryData (state) { // 清除临时数据
    state.temporaryData = {}
  }
}
const actions = {
  setTemporaryData (store, data) { // 存储临时数据
    store.commit('setTemporaryData', data)
  },
  removeTemporaryData (store) { // 清除临时数据
    store.commit('removeTemporaryData')
  }
}

export default {
  namespaced: true,
  // state,
  mutations,
  actions
}
