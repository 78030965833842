export default [
  {
    path: 'score-summary-details/:id/:type?',
    name: 'score-summary-details',
    component: () => import('@/views/ihrm/components/staff-list/score-summary-details.vue'),
    meta: { title: '评分详情' },
    props: true
  },
  {
    path: 'edit-staff/:id',
    name: 'edit-staff',
    component: () => import('@/views/ihrm/components/staff-list/edit-staff.vue'),
    meta: { title: '编辑职员信息' },
    props: true
  },
  {
    path: 'details-staff/:id/:type?/:viewType?',
    name: 'details-staff',
    component: () => import('@/views/ihrm/components/staff-list/details-staff.vue'),
    meta: { title: '职员信息详情' },
    props: true
  },
  {
    path: 'details-induction/:id',
    name: 'details-induction',
    component: () => import('@/views/ihrm/components/Induction/details-induction.vue'),
    meta: { title: '审核详情' }, // 入职审核详情
    props: true
  },
  {
    path: 'details-organization-subject/:id',
    name: 'details-organization-subject',
    component: () => import('@/views/ihrm/components/organization/organization-subject/details-organization-subject.vue'),
    meta: { title: '详情' }, // 组织主体详情
    props: true
  },
  {
    path: 'edit-organization-subject/:id?',
    name: 'edit-organization-subject',
    component: () => import('@/views/ihrm/components/organization/organization-subject/edit-organization-subject.vue'),
    meta: { title: '编辑' }, // 组织主体编辑与新增
    props: true
  },
  { // 跳转页面的日志详情
    path: 'message-list-log/:id',
    name: 'message-list-log',
    component: () => import('@/views/journal/components/menber-log-controller/message-list-log.vue'),
    meta: { title: '日志详情' },
    props: true
  }
]
