import ReturnPage from '@/views/return-page' // 返回页面结构
export default [{
  path: '/new-company-homepage',
  name: 'return-new-company-homepage',
  component: ReturnPage,
  children: [
    {
      path: 'new-homepage-article/:id',
      name: 'new-homepage-article',
      component: () => import('@/views/new-company-home/components/company-homepage/homepage-article.vue'),
      meta: { title: '文章详情', rekeepAlive: true, scrollTop: 0, name: 'newHomepageArticle' },
      props: true
    }, // 一级
    {
      path: 'details-new-homepage-article/:id/:type',
      name: 'details-new-homepage-article',
      component: () => import('@/views/new-company-home/components/company-homepage/details-homepage-article.vue'),
      meta: { title: '文章详情' },
      props: true
    }, // 二级，直接文章
    {
      path: 'article-list/:id',
      name: 'article-list',
      component: () => import('@/views/new-company-home/components/company-homepage/article-list.vue'),
      meta: { title: '文章列表', rekeepAlive: true, scrollTop: 0, name: 'articleList' },
      props: true
    } // 三级，文章列表
  ]
}]
