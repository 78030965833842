<template>
  <van-image
    ref="image"
    :width="width"
    :height="height"
    fit="cover"
    :radius="radius"
    :lazy-load="lazy_load"
    :src="imgUrlBool?imgUrl:imgTypeFn(imgUrl)"
    @click="openSeeFile?onSeeFile(imgUrl):''"
  >
    <template #loading><slot name="loading" /></template>
    <template #error><slot name="error" /></template>
  </van-image>
</template>

<script>
import { commontGetImg, commontGetImgList } from '@/api/user'
import { imgTypeFn, openFileFn } from '@/utils/index'
import { ImagePreview } from 'vant'
export default {
  name: 'OriginalDrawingImg',
  props: {
    imgUrl: {
      type: String,
      default: ''
    },
    imgUrlBool: {
      default: false
    },
    imgUrlArr: {
      type: Array,
      default: () => []
    },
    index: {
      type: [String, Number],
      default: '0'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    radius: {
      type: [String, Number],
      default: '0'
    },
    openSeeFile: {
      default: true
    },
    lazy_load: {
      default: false
    }
  },
  data () {
    return {
      imgArr: []
    }
  },
  methods: {
    imgTypeFn,
    ImagePreview,
    async handleiImgArr () {
      if (this.imgUrlArr.length <= 0) {
        return new Promise((resolve, reject) => resolve())
      }
      const info = ['jpg', 'jpeg', 'png', 'gif']
      this.imgArr = []
      this.imgUrlArr.forEach((item) => {
        if (!item.includes('.')) { // 没有点的话那就是坏文件， 随便链接个点让他正常
          item = item + '.' + 'jpg'
        }
        let h = item.split('.')
        h = h[h.length - 1].toLowerCase() // 转小写
        if (info.includes(h)) {
          this.imgArr.push(item)
        }
      })
      if (this.imgArr.length > 0) {
        return await commontGetImgList(this.imgArr)
      } else {
        return new Promise((resolve, reject) => resolve())
      }
    },
    onSeeFile (url) {
      if (!url) return
      const info = ['jpg', 'jpeg', 'png', 'gif', 'webp']
      if (!url.includes('.')) { // 没有点的话那就是坏文件， 随便链接个点让他正常
        url = url + '.' + 'jpg'
      }
      let h = url.split('.')
      h = h[h.length - 1].toLowerCase() // 转小写
      if (info.includes(h)) {
        this.handleiImgArr().then(res => {
          if (res && res.data.length > 0) {
            ImagePreview({
              images: res.data,
              startPosition: this.index - 0
            })
          } else {
            commontGetImg({ key: url }).then(mse => {
              ImagePreview({
                images: [mse.data]
              })
            })
          }
        }).catch(() => {})
      } else {
        this.$dialog.confirm({
          message: '确定要下载该文件吗？'
        }).then(() => {
          openFileFn(url, '文件')
        }).catch(() => {})
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
