import Homelayout from '@/views/home' // 主页面结构

export default [{
  path: '/processSignOff',
  component: Homelayout,
  children: [
    {
      path: 'workflow',
      name: 'workflow',
      component: () => import('@/views/processSignOff/workflow.vue'),
      meta: { title: '工作流', keepAlive: true, scrollTop: 0, long: true, name: 'workFlow' }
    },
    {
      path: 'signing',
      name: 'signing',
      component: () => import('@/views/processSignOff/signing.vue'),
      meta: { title: '签核中', keepAlive: true, scrollTop: 0, name: 'signing' }
    },
    {
      path: 'completed',
      name: 'completed',
      component: () => import('@/views/processSignOff/completed.vue'),
      meta: { title: '已完成', keepAlive: true, scrollTop: 0, name: 'completed' }
    },
    {
      path: 'rejected',
      name: 'rejected',
      component: () => import('@/views/processSignOff/rejected.vue'),
      meta: { title: '已拒绝', keepAlive: true, scrollTop: 0, name: 'rejected' }
    },
    {
      path: 'to-be-signed-off',
      name: 'toBeSignedOff',
      component: () => import('@/views/processSignOff/to-be-signed-off.vue'),
      meta: { title: '待签核', keepAlive: false, scrollTop: 0, name: 'toBeSignedOff' }
    },
    {
      path: 'signed-off',
      name: 'signed-off',
      component: () => import('@/views/processSignOff/signed-off.vue'),
      meta: { title: '已签核', keepAlive: true, scrollTop: 0, name: 'signedOff' }
    }
  ]
}]
