export default [
  {
    path: 'details-picking-record/:id/:bathId',
    name: 'details-picking-record',
    component: () => import('@/views/customer-pick-up/components/picking-record/details-picking-record.vue'),
    meta: { title: '订单提货详情' },
    props: true
  },
  {
    path: 'add-packaging-recycling',
    name: 'add-packaging-recycling',
    component: () => import('@/views/customer-pick-up/components/packaging-recycling/add-packaging-recycling.vue'),
    meta: { title: '新增包装回收' },
    props: true
  },
  {
    path: 'details-stock-record/:id/:bathId',
    name: 'details-stock-record',
    component: () => import('@/views/customer-pick-up/components/picking-record/details-stock-record.vue'),
    meta: { title: '存货提货详情' },
    props: true
  },
  {
    path: 'details-individual-record/:bathId/:id?',
    name: 'details-individual-record',
    component: () => import('@/views/customer-pick-up/components/picking-record/details-individual-record.vue'),
    meta: { title: '详情' }, // 散客 提货记录详情
    props: true
  },
  {
    path: 'details-stock/:id',
    name: 'details-stock',
    component: () => import('@/views/customer-pick-up/components/stock-list/details-stock.vue'),
    meta: { title: '存货详情' },
    props: true
  },
  {
    path: 'details-return-record/:id/:signId',
    name: 'details-return-record',
    component: () => import('@/views/customer-pick-up/components/return-record/details-return-record.vue'),
    meta: { title: '退货详情' },
    props: true
  },
  {
    path: 'scan-code-gift-details/:id',
    name: 'scan-code-gift-details',
    component: () => import('@/views/customer-pick-up/components/scan-code-gift/scan-code-gift-details.vue'),
    meta: { title: '扫码详情' },
    props: true
  },
  {
    path: 'scan-code-gift-modify-details/:id',
    component: () => import('@/views/customer-pick-up/components/scan-code-gift/scan-code-gift-details.vue'),
    meta: { title: '扫码详情' },
    props: true
  }
]
