import store from '@/store'

/**
 *  获取是否存在权限
 * @param {String} val 权限码
 * @returns
 */
export function getPermisCode (val) {
  return store.state.user.userInfo.memuCodeList && store.state.user.userInfo.memuCodeList.includes(val)
}
