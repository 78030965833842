<template>
  <div id="modify_password">
    <van-form @submit="onSubmit" autocomplete="off">
      <van-cell-group inset>
        <van-field
          class="screenshot"
          v-model.trim="params.ackPassWord"
          type="password"
          name="ackPassWord"
          label="新密码"
          placeholder="新密码"
          required
          :rules="[
            { required: true, message: '请填写新密码' },
            { pattern: /^[0-9a-zA-Z][\S]{5,19}$/, message: '长度在 6 ~ 20 个字符' }
          ]"
        />
        <van-field
          class="screenshot"
          v-model.trim="params.password"
          type="password"
          name="password"
          label="确认密码"
          placeholder="确认密码"
          required
          :rules="[{ required: true, message: '请再次填写新密码' }]"
        />
      </van-cell-group>
      <van-button round block type="primary" v-show="false" ref="forgetpwdmine" class="bgBule border_0" native-type="submit">
        修改
      </van-button>
    </van-form>
  </div>
</template>

<script>
import { resetPassword } from '@/api/user'
export default {
  name: 'modifyPassword',
  props: {
    oldShow: { // 密码过期 为 false
      default: true
    }
  },
  data () {
    return {
      params: { // 更新数据参数
        ackPassWord: '',
        oldPassword: '',
        password: ''
      }
    }
  },
  methods: {
    onSubmit (val) { // 保存
      this.$store.commit('loading/showLoading', '修改中...')
      val.headImg = this.transferFile
      resetPassword(val).then(res => {
        if (res.code == 200) {
          this.$dialog({
            message: '已成功修改密码'
          }).then(() => {
            this.$store.dispatch('user/setPasswordStatus', false)
            window.location.reload()
          })
        }
      }).catch(err => {
        console.log(err)
        this.$toast('修改失败')
        // this.$store.dispatch('user/setPasswordStatus', true)
      })
    }
  }
}
</script>

<style lang="less" scoped>
#modify_password{
  padding: 8px 10px;
}
/deep/.van-cell-group--inset{
  margin: 0;
}
</style>
