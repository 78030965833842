export default [
  {
    path: 'details-order/:id',
    name: 'details-order',
    component: () => import('@/views/my-service/components/my-order/details-order.vue'),
    meta: { title: '产品详情' },
    props: true
  },
  {
    path: 'details-payment/:id',
    name: 'details-payment',
    component: () => import('@/views/my-service/components/my-order/details-payment.vue'),
    meta: { title: '付款详情' },
    props: true
  },
  {
    path: 'details-receiving/:batchId/:id',
    name: 'details-receiving',
    component: () => import('@/views/my-service/components/my-order/details-receiving.vue'),
    meta: { title: '收货详情' },
    props: true
  },
  {
    path: 'details-return-finance/:id',
    name: 'details-return-finance',
    component: () => import('@/views/my-service/components/my-order/details-return-finance.vue'),
    meta: { title: '退货财务' },
    props: true
  },
  {
    path: 'details-return-warehouse/:id',
    name: 'details-return-warehouse',
    component: () => import('@/views/my-service/components/my-order/details-return-warehouse.vue'),
    meta: { title: '退货仓库' },
    props: true
  },
  {
    path: 'details-history-return/:id',
    name: 'details-history-return',
    component: () => import('@/views/my-service/components/my-order/details-history-return.vue'),
    meta: { title: '退货' },
    props: true
  },
  {
    path: 'details-history/:id',
    name: 'details-history',
    component: () => import('@/views/my-service/components/my-order/details-history.vue'),
    meta: { title: '历史订单详情' },
    props: true
  },
  {
    path: 'details-advance/:id',
    name: 'details-advance',
    component: () => import('@/views/my-service/components/advance-payment/details-advance.vue'),
    meta: { title: '预存款详情' },
    props: true
  },
  {
    path: 'detailed-advance/:id',
    name: 'detailed-advance',
    component: () => import('@/views/my-service/components/advance-payment/detailed-advance.vue'),
    meta: { title: '预存款明细' },
    props: true
  },
  {
    path: 'edit-advance/:id?',
    name: 'edit-advance',
    component: () => import('@/views/my-service/components/advance-payment/edit-advance.vue'),
    meta: { title: '编辑预存款' },
    props: true
  },
  {
    path: 'settlement-order',
    name: 'settlement-order',
    component: () => import('@/views/my-service/components/shopping-cart/settlement-order.vue'),
    meta: { title: '确认订单' },
    props: true
  }
  // my-service 模块结束
]
