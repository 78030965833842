<template>
  <div class="ex-box">
    <div class="head-h3">
      <div>公告</div>
      <div class="close" @click="onClose"><van-icon name="cross" size="22" color="#333" /></div>
    </div>
    <div class="ex-main" id="T-block">
      <div class="ex-block">
        <div class="ex_title">
          <p class="title_h2">{{ detailsList.infoTitle }}</p>
          <!-- <span class="title_h3">{{ getDate(detailsList.createTime) }}</span> -->
        </div>
        <div class="ex_content">
          <div v-html="detailsList.info" class="content_text" @click="onWhoImg"></div>
        </div>
        <div class="ex_footer">
          <!-- <span>已有 {{detailsList.commVoList? detailsList.commVoList.length : 0}} 人阅读</span> -->
          <!-- <span class="title_h3">{{ getDate(detailsList.createTime) }}</span> -->
        </div>
      </div>
      <div class="el-block" v-if="detailsList.file">
        <van-cell title="附件">
          <template #right-icon>
            <OriginalDrawingImg
              width="50"
              height="50"
              :imgUrl="detailsList.file"
            />
          </template>
        </van-cell>
      </div>
      <div class="title_h3">{{ getDate(detailsList.createTime) }}</div>
    </div>
    <van-dialog v-model="showForgetpwd" title="关闭后可去首页继续查看" :before-close="beforeClose" show-cancel-button>
      <div class="closePoint">
        <van-checkbox v-model="checked">当天内不会重新弹出当前公告</van-checkbox>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { homeTipPublicConfig } from '@/api/home/page'
import { getDate } from '@/utils'
import { ImagePreview } from 'vant'
import OriginalDrawingImg from '@/components/OriginalDrawingImg.vue'
export default {
  props: {
    overlayList: {
      default: () => {}
    },
    type: { // 是否直接关闭
      default: false
    }
  },
  name: 'LatestNoticeSystem',
  data () {
    return {
      detailsList: this.overlayList,
      showForgetpwd: false,
      checked: false,
      imgUrl: require('@/assets/download/read.png')
    }
  },
  mounted () {
    const list = document.querySelector('#T-block')
    list.addEventListener('touchmove', e => e.stopPropagation(), false)
  },
  methods: {
    getDate,
    onWhoImg (e) { // 点击图片放大
      if (e.target.nodeName == 'IMG') {
        ImagePreview([e.target.src])
      }
    },
    onClose () {
      if (this.type) { // 直接关闭
        this.$emit('close')
        return
      }
      this.showForgetpwd = true
    },
    async beforeClose (action, done) { // 忘记密码弹窗
      if (action === 'confirm') { // 点击确定走这里
        if (this.checked) {
          try {
            await homeTipPublicConfig({ id: this.detailsList.infoId })
            this.$emit('close')
          } catch (err) {
            this.$emit('close')
          }
        } else {
          this.$emit('close')
        }
        return done(true)
      } else { // 点击取消走这里
        return done(true) // 关闭弹窗, true可以省略
      }
    }
  },
  components: {
    OriginalDrawingImg
  }
}
</script>

<style lang="less" scoped>
.ex-box {
  height: 100%;
  padding: 7px;
  .head-h3{
    line-height: 40px;
    text-align: center;
    position: relative;
    color: #333;
    font-weight: bold;
    position: relative;
    &::after {
      position: absolute;
      content: ' ';
      background: linear-gradient(to left, #fff, var(--bule-color), #fff);
      height: 1px;
      left: 0;
      right: 0;
    }
    .close{
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .ex-main {
    // border: 1px double var(--red-color);
    overflow-y: scroll;
    border-width: 5px;
    border-radius: 5px;
    min-height: 100%;
    max-height: -webkit-calc(100vh - 10rem);
    max-height: -moz-calc(100vh - 10rem);
    max-height: calc(100vh - 10rem);
    padding: 8px 10px 8px;
    .ex-block{
      .ex_title{
        margin-top:5px;
        .title_h2 {
          font-size: 1.1rem;
          font-weight: bold;
          text-align: center;
          color: #333;
          line-height: 1.5em;
        }
      }
      .ex_content{
        padding: 10px 0;
        /deep/.content_text{
          p{
            line-height: 25px;
            text-align: justify;
            color: #333;
          }
          img{
            width: 100%;
          }
        }
        .content_img{
          width: 100%;
          margin-top: 10px;
          overflow: hidden;
          border-radius: 9px;
          img{
            width: 100%;
          }
        }
      }
      .ex_footer{
        color: #999;
        font-size: 0.7em;
        margin-bottom: 10px;
      }
    }
    .title_h3{
      text-align: right;
      font-size: 0.8rem;
      color: #999;
      line-height: 1.5em;
    }
  }
}
.closePoint {
  padding: 20px 0;
  /deep/ .van-checkbox {
    justify-content: center;
    font-size: 14px;
  }
}
</style>
