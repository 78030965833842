export default [
  // 进货下单
  // // 新加订单
  {
    path: 'details-new-order/:id/:tagId?',
    name: 'details-new-order',
    component: () => import('@/views/purchase-order/components/new-order/product-list/details-new-order.vue'),
    meta: { title: '产品详情' },
    props: true
  },
  // // 选定下单
  {
    path: 'new-settlement-order',
    name: 'new-settlement-order',
    component: () => import('@/views/purchase-order/components/new-order/select-order/new-settlement-order.vue'),
    meta: { title: '结算', rekeepAlive: true, scrollTop: 0, name: 'newSettlementOrder' },
    props: true
  },
  // 我的订单
  {
    path: 'edit-new-order/:id',
    name: 'edit-new-order',
    component: () => import('@/views/purchase-order/components/my-order/edit-order.vue'),
    meta: { title: '修改订单' },
    props: true
  },
  {
    path: 'details-new-payment/:id',
    name: 'details-new-payment',
    component: () => import('@/views/purchase-order/components/my-order/details-payment.vue'),
    meta: { title: '付款详情' },
    props: true
  },
  {
    path: 'payment-merge',
    name: 'payment-merge',
    component: () => import('@/views/purchase-order/components/my-order/payment-merge.vue'),
    meta: { title: '合并支付' },
    props: true
  },
  {
    path: 'details-new-receiving/:batchId/:id',
    name: 'details-new-receiving',
    component: () => import('@/views/purchase-order/components/my-order/details-receiving.vue'),
    meta: { title: '收货详情' },
    props: true
  },
  {
    path: 'details-new-return-finance/:id',
    name: 'details-new-return-finance',
    component: () => import('@/views/purchase-order/components/my-order/details-return-finance.vue'),
    meta: { title: '退货财务' },
    props: true
  },
  {
    path: 'details-new-return-warehouse/:id',
    name: 'details-new-return-warehouse',
    component: () => import('@/views/purchase-order/components/my-order/details-return-warehouse.vue'),
    meta: { title: '退货仓库' },
    props: true
  },
  {
    path: 'details-new-history-return/:id',
    name: 'details-new-history-return',
    component: () => import('@/views/purchase-order/components/my-order/details-history-return.vue'),
    meta: { title: '退货' },
    props: true
  },
  {
    path: 'details-new-history/:id',
    name: 'details-new-history',
    component: () => import('@/views/purchase-order/components/my-order/details-history.vue'),
    meta: { title: '历史订单详情' },
    props: true
  },
  // 预存货款
  {
    path: 'details-new-advance/:id',
    name: 'details-new-advance',
    component: () => import('@/views/purchase-order/components/advance-payment/details-advance.vue'),
    meta: { title: '预存款详情' },
    props: true
  },
  {
    path: 'detailed-new-advance/:id',
    name: 'detailed-new-advance',
    component: () => import('@/views/purchase-order/components/advance-payment/detailed-advance.vue'),
    meta: { title: '预存款明细' },
    props: true
  },
  {
    path: 'edit-new-advance/:id?',
    name: 'edit-new-advance',
    component: () => import('@/views/purchase-order/components/advance-payment/edit-advance.vue'),
    meta: { title: '编辑预存款' },
    props: true
  },
  // 货物管理
  // // 出入库
  {
    path: 'details-purchase-warehousing-record/:id/:type',
    name: 'details-purchase-warehousing-record',
    component: () => import('@/views/purchase-order/components/picking-management/new-warehousing-record/details-new-warehousing-record.vue'),
    meta: { title: '详情' }, // 出入库详情
    props: true
  },
  {
    path: 'add-purchase-stock-out',
    name: 'add-purchase-stock-out',
    component: () => import('@/views/purchase-order/components/picking-management/new-warehousing-record/add-purchase-stock-out.vue'),
    meta: { title: '新增' }, // 出入库新增
    props: true
  },
  // // 仓库列表
  {
    path: 'purchase-warehouse-store/:id',
    name: 'purchase-warehouse-store',
    component: () => import('@/views/purchase-order/components/picking-management/new-warehouse-list/new-warehouse-store.vue'),
    meta: { title: '库存' }, // 库存
    props: true
  },
  {
    path: 'details-purchase-warehouse-management/:id',
    name: 'details-purchase-warehouse-management',
    component: () => import('@/views/purchase-order/components/picking-management/new-warehouse-list/details-new-warehouse-management.vue'),
    meta: { title: '详情' }, // 仓库详情
    props: true
  },
  {
    path: 'edit-purchase-warehouse-management/:id?',
    name: 'edit-purchase-warehouse-management',
    component: () => import('@/views/purchase-order/components/picking-management/new-warehouse-list/edit-new-warehouse-management.vue'),
    meta: { title: '编辑' }, // 编辑
    props: true
  },
  // // 调货结算
  {
    path: 'details-transfer-settlement/:id',
    name: 'details-transfer-settlement',
    component: () => import('@/views/purchase-order/components/picking-management/transfer-settlement/details-transfer-settlement.vue'),
    meta: { title: '明细' },
    props: true
  }
]
