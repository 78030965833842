import { ImagePreview, Toast } from 'vant'
import { isEmptyStr } from '@/utils'

const enlarge = { // 放大图片
  inserted: function (el) {
    el.addEventListener('click', () => {
      ImagePreview([el.src])
    })
  }
}

const wintop = { // 点击按钮回归页面顶部
  inserted: function (el) {
    el.addEventListener('click', () => {
      if (document.querySelector('#main')) {
        document.querySelector('#main').scrollTop = 0
      } else {
        document.documentElement.scrollTop = 0
      }
    })
  }
}

const resize = { // 动态获取高度
  bind (el, binding) {
    let width = ''; let height = ''
    function isResize () {
      const style = document.defaultView.getComputedStyle(el)
      if (width !== style.width || height !== style.height) {
        binding.value()
      }
      width = style.width
      height = style.height
    }
    el._vueSetInterval_ = setInterval(isResize, 100)
  },
  unbind (el) {
    clearInterval(el._vueSetInterval_)
  }
}

const debounce = { // 按钮防抖
  inserted: function (el, binding) {
    let timer
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        binding.value()
      }, 1000)
    })
  }
}

let instructTime = null
const copy = { // 复制文本
  inserted: function (el) {
    // 聚焦元素
    el.addEventListener('touchstart', function () {
      instructTime = setTimeout(() => {
        const text = el.getAttribute('data-copy-text')
        if (isEmptyStr(text)) return
        if (navigator.clipboard) {
          navigator.clipboard.writeText(text).then(function () {
            Toast(`已复制文本\n${text}`)
          }, function () {
            finalCopy(text)
          })
        } else {
          finalCopy(text)
        }
      }, 1000)
    })
    el.addEventListener('touchend', function () {
      clearTimeout(instructTime)
    })
  }
}
function finalCopy (text) { // 复制文本 兼容ios
  const input = document.createElement('input')
  input.value = text
  document.body.appendChild(input)
  input.select()
  input.setSelectionRange(0, 99999)
  document.execCommand('copy')
  Toast(`已复制文本\n${text}`)
  document.body.removeChild(input)
}
export { enlarge, wintop, resize, debounce, copy }
