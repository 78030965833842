import Service from '@/utils/request.js'

/**
 * 消息详情
 * @param {*} params
 * @returns
 */
export const msgDetail = (params) => Service.post('/msg/detail', params)
/**
 * 待签核数
 * @param {*} params
 * @returns
 */
export const msgFlowCount = (params) => Service.post('/msg/flowCount', params)
/**
 * 消息列表
 * @param {*} params
 * @returns
 */
export const msgList = (params) => Service.post('/msg/list', params)
/**
 * 未读消息数
 * @param {*} params
 * @returns
 */
export const msgMsgCount = (params) => Service.post('/msg/msgCount', params)
/**
 * 一键阅读
 * @param {*} params
 * @returns
 */
export const msgAllRead = (params) => Service.post('/msg/allRead', params)
