<template>
  <div class="FooterFixed" ref="FooterFixed">
    <slot name="ex_footer"></slot>
  </div>
</template>

<script>
export default {
  name: 'FooterFixed',
  props: {
    tlement: { // 下边距
      type: String,
      default: '0px'
    },
    telescoping: { // 是否开启伸缩
      type: Boolean,
      default: true
    }
  },
  mounted () {
    // 初始化结算元素距离页面底部
    const that = this
    if (this.telescoping) {
      this.$nextTick(() => {
        that.$refs.FooterFixed.style.bottom = this.$store.state.user.statusIndex ? this.tlement : '0px'
      })
    } else {
      this.$nextTick(() => {
        that.$refs.FooterFixed.style.bottom = this.tlement
      })
    }
  },
  computed: {
    statusIndex () {
      return this.$store.state.user.statusIndex
    }
  },
  watch: {
    statusIndex: {
      handler (newVal, oldVal) { // 修该结算元素距离页面底
        if (this.telescoping) {
          this.$refs.FooterFixed.style.bottom = newVal ? this.tlement : '0px'
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .FooterFixed {
    position: fixed;
    // bottom: 50px;
    left: 0;
    width: 100%;
    height: 50px;
    background: #fff;
    box-shadow: 0 0 10px #dfdfdfa6;
    .footer {
      height: 50px;
      padding: 5px 12px;
      button{
        height: 40px;
      }
    }
    .footer_flex {
      height: 50px;
      padding: 5px 12px;
      display: flex;
      button{
        height: 40px;
        flex: 1;
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
</style>
