import Homelayout from '@/views/home' // 主页面结构

export default [{
  path: '/customer-pick-up',
  component: Homelayout,
  children: [
    {
      path: 'scan-code-gift',
      name: 'scan-code-gift',
      component: () => import('@/views/customer-pick-up/scan-code-gift.vue'),
      meta: { title: '扫码礼品', keepAlive: false, scrollTop: 0, name: 'scanCodeGift' }
    },
    {
      path: 'packaging-recycling',
      name: 'packaging-recycling',
      component: () => import('@/views/customer-pick-up/packaging-recycling.vue'),
      meta: { title: '回收包装', keepAlive: false, scrollTop: 0, name: 'packagingRecycling' }
    },
    {
      path: 'delivery-reminder',
      name: 'delivery-reminder',
      component: () => import('@/views/store-management/components/customer-management/delivery-reminder.vue'),
      meta: { title: '提货提醒' }
    },
    {
      path: 'picking-record',
      name: 'picking-record',
      component: () => import('@/views/customer-pick-up/picking-record.vue'),
      meta: { title: '提货记录' }
    },
    {
      path: 'picking-application',
      name: 'picking-application',
      component: () => import('@/views/customer-pick-up/picking-application.vue'),
      meta: { title: '提货申请', keepAlive: true, scrollTop: 0, name: 'pickingRecord' }
    },
    {
      path: 'inventory-registration',
      name: 'inventory-registration',
      component: () => import('@/views/customer-pick-up/inventory-registration.vue'),
      meta: { title: '存货登记' }
    },
    {
      path: 'stock-list',
      name: 'stock-list',
      component: () => import('@/views/customer-pick-up/stock-list.vue'),
      meta: { title: '存货记录', keepAlive: true, scrollTop: 0, name: 'stockList' }
    },
    {
      path: 'return-record',
      name: 'return-record',
      component: () => import('@/views/customer-pick-up/return-record.vue'),
      meta: { title: '退货记录', keepAlive: true, scrollTop: 0, name: 'returnRecord' }
    },
    {
      path: 'return-request',
      name: 'return-request',
      component: () => import('@/views/customer-pick-up/return-request.vue'),
      meta: { title: '退货申请' }
    },
    {
      path: 'cross-store-pickup',
      name: 'cross-store-pickup',
      component: () => import('@/views/customer-pick-up/cross-store-pickup.vue'),
      meta: { title: '跨店提货' }
    }
  ]
}]
