import Homelayout from '@/views/home' // 主页面结构

// 行政管理
export default [{
  path: '/administrative-management',
  component: Homelayout,
  children: [
    {
      path: 'article-borrowing',
      name: 'article-borrowing-menu',
      component: () => import('@/views/administrative-management/article-borrowing.vue'),
      meta: { title: '物品借用', keepAlive: true, scrollTop: 0, name: 'articleBorrowing' }
    },
    {
      path: 'outreach-personnel',
      name: 'outreach-personnel',
      component: () => import('@/views/administrative-management/outreach-personnel.vue'),
      meta: { title: '外联人员', keepAlive: true, scrollTop: 0, name: 'outreachPersonnel' }
    },
    {
      path: 'document-seal',
      name: 'document-seal',
      component: () => import('@/views/administrative-management/document-seal.vue'),
      meta: { title: '文件盖章', keepAlive: true, scrollTop: 0, name: 'documentSeal' }
    },
    {
      path: 'assist-work-order',
      name: 'mes-assist-work-order',
      component: () => import('@/views/message-management/assist-work-order.vue'),
      meta: { title: '协助工单', keepAlive: true, scrollTop: 0, name: 'mesAssistWorkOrder' }
    }
  ]
}]
