export default [
  // 经销商订单
  {
    path: 'details-customer-service-review/:id',
    name: 'details-customer-service-review',
    component: () => import('@/views/distributor/components/dealer-order/customer-service-review/details-customer-service-review.vue'),
    meta: { title: '审核详情' },
    props: true
  },
  {
    path: 'details-history-order/:id',
    name: 'details-history-order',
    component: () => import('@/views/distributor/components/dealer-order/history-order/details-history-order.vue'),
    meta: { title: '历史订单详情' },
    props: true
  },
  {
    path: 'details-payment-order/:id',
    name: 'details-payment-order',
    component: () => import('@/views/distributor/components/dealer-order/stay-payment-order/details-payment-order.vue'),
    meta: { title: '待付款详情' },
    props: true
  },
  {
    path: 'details-financial-review/:id',
    name: 'details-financial-review',
    component: () => import('@/views/distributor/components/dealer-order/financial-review/details-financial-review.vue'),
    meta: { title: '财务审核详情' },
    props: true
  },
  {
    path: 'details-warehous-delivery/:id/:storeId/:storeStatus',
    name: 'details-warehous-delivery',
    component: () => import('@/views/distributor/components/dealer-order/warehous-delivery/details-warehous-delivery.vue'),
    meta: { title: '仓库发货详情' },
    props: true
  },
  {
    path: 'details-warehouse-dispatch/:id/:storeId',
    name: 'details-warehouse-dispatch',
    component: () => import('@/views/distributor/components/dealer-order/warehouse-dispatch/details-warehouse-dispatch.vue'),
    meta: { title: '仓库派单详情' },
    props: true
  },
  {
    path: 'place-order/:id?/:storeId?',
    name: 'place-order',
    component: () => import('@/views/distributor/components/dealer-order/warehouse-dispatch/place-order.vue'),
    meta: { title: '采购清单' },
    props: true
  },
  {
    path: 'details-returnOrder-finance/:id',
    name: 'details-returnOrder-finance',
    component: () => import('@/views/distributor/components/dealer-order/return-order/details-returnOrder-finance.vue'),
    meta: { title: '退货财务' },
    props: true
  },
  {
    path: 'details-returnOrder-warehouse/:id',
    name: 'details-returnOrder-warehouse',
    component: () => import('@/views/distributor/components/dealer-order/return-order/details-returnOrder-warehouse.vue'),
    meta: { title: '退货仓库' },
    props: true
  },
  // 采购订单
  {
    path: 'details-purchase-finance/:id',
    name: 'details-purchase-finance',
    component: () => import('@/views/distributor/components/purchase-order/purchaseList/details-purchase-finance.vue'),
    meta: { title: '财务详情' },
    props: true
  },
  {
    path: 'details-purchase-warehouse/:id',
    name: 'details-purchase-warehouse',
    component: () => import('@/views/distributor/components/purchase-order/purchaseList/details-purchase-warehouse.vue'),
    meta: { title: '仓库详情' },
    props: true
  },
  {
    path: 'set-order',
    name: 'set-order',
    component: () => import('@/views/distributor/components/purchase-order/new-procurement/set-order.vue'),
    meta: { title: '采购清单', rekeepAlive: true, scrollTop: 0, name: 'setOrder' },
    props: true
  },
  {
    path: 'details-consolidated-payment/:id',
    name: 'details-consolidated-payment',
    component: () => import('@/views/distributor/components/purchase-order/consolidated-payment/details-consolidated-payment.vue'),
    meta: { title: '合并支付详情' },
    props: true
  },
  // 预存货款
  {
    path: 'details-advance-distributor/:id',
    name: 'details-advance-distributor',
    component: () => import('@/views/distributor/components/advance-payment/distributor-payment/details-advance.vue'),
    meta: { title: '预存货款详情' }, // 经销商
    props: true
  },
  {
    path: 'detailed-advance-distributor/:id',
    name: 'detailed-advance-distributor',
    component: () => import('@/views/distributor/components/advance-payment/distributor-payment/detailed-advance.vue'),
    meta: { title: '预存货款明细' }, // 经销商
    props: true
  },
  {
    path: 'edit-advance-distributor/:id',
    name: 'edit-advance-distributor',
    component: () => import('@/views/distributor/components/advance-payment/distributor-payment/edit-advance.vue'),
    meta: { title: '客服审核' }, // 经销商预存货款
    props: true
  },
  {
    path: 'finance-advance-distributor/:id',
    name: 'finance-advance-distributor',
    component: () => import('@/views/distributor/components/advance-payment/distributor-payment/finance-advance.vue'),
    meta: { title: '财务审核' }, // 经销商预存货款
    props: true
  },
  {
    path: 'details-advance-manufacturer/:id',
    name: 'details-advance-manufacturer',
    component: () => import('@/views/distributor/components/advance-payment/manufacturer-payment/details-advance.vue'),
    meta: { title: '预存货款详情' }, // 厂商
    props: true
  },
  {
    path: 'detailed-advance-manufacturer/:id',
    name: 'detailed-advance-manufacturer',
    component: () => import('@/views/distributor/components/advance-payment/manufacturer-payment/detailed-advance.vue'),
    meta: { title: '预存货款明细' }, // 厂商
    props: true
  },
  {
    path: 'edit-advance-manufacturer/:id?',
    name: 'edit-advance-manufacturer',
    component: () => import('@/views/distributor/components/advance-payment/manufacturer-payment/edit-advance.vue'),
    meta: { title: '审核' }, // 厂商
    props: true
  }
  // // 员工自购
  // {
  //   path: 'self-purchase-audit/:id',
  //   name: 'self-purchase-audit',
  //   component: () => import('@/views/distributor/components/employee-self-purchase/self-purchase-audit.vue'),
  //   meta: { title: '自购订单审核' },
  //   props: true
  // },
  // {
  //   path: 'self-purchase-financial-review/:id',
  //   name: 'self-purchase-financial-review',
  //   component: () => import('@/views/distributor/components/employee-self-purchase/self-purchase-financial-review.vue'),
  //   meta: { title: '自购财务审核' },
  //   props: true
  // },
  // {
  //   path: 'self-purchase-order-shipment/:id',
  //   name: 'self-purchase-order-shipment',
  //   component: () => import('@/views/distributor/components/employee-self-purchase/self-purchase-order-shipment.vue'),
  //   meta: { title: '自购订单发货' },
  //   props: true
  // },
  // {
  //   path: 'details-of-resale/:id',
  //   name: 'details-of-resale',
  //   component: () => import('@/views/distributor/components/employee-self-purchase/details-of-resale.vue'),
  //   meta: { title: '转转卖单详情' },
  //   props: true
  // },
  // {
  //   path: 'details-of-redeposit/:id',
  //   name: 'details-of-redeposit',
  //   component: () => import('@/views/distributor/components/employee-self-purchase/details-of-redeposit.vue'),
  //   meta: { title: '转预存款详情' },
  //   props: true
  // }
]
