const mutations = {
  setOrderSetLement (state, data) { // 存储购物车结算的订单数据
    state.orderData = data
  },
  removeOrder (state) { // 清除结算订单数据
    state.orderData = []
  },
  setDetailsPayment (state, data) { // 存储付款详情数据
    state.paymentData = data
  },
  removePayment (state) { // 清除存储付款详情数据
    state.paymentData = []
  }
}
const actions = {
  getOrderSetLement (store, data) { // 存储购物车结算的订单数据
    store.commit('setOrderSetLement', data)
  },
  removeOrder (store) { // 清除结算订单数据
    store.commit('removeOrder')
  },
  getDetailsPayment (store, data) { // 存储付款详情数据
    store.commit('setDetailsPayment', data)
  },
  removePayment (store) { // 清除存储付款详情数据
    store.commit('removePayment')
  }
}

export default {
  namespaced: true,
  // state,
  mutations,
  actions
}
