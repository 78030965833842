export default [
  {
    path: 'receiving-address/:info',
    name: 'receiving-address',
    component: () => import('@/views/system/receiving-address.vue'),
    meta: { title: '选择收货地址' },
    props: true
  },
  {
    path: 'edit-address/:id?',
    name: 'edit-address',
    component: () => import('@/views/system/components/receiving-address/edit-address.vue'),
    meta: { title: '编辑地址' },
    props: true
  },
  // 集团管理
  //  // 集团列表
  {
    path: 'subordinate-group/:id',
    name: 'subordinate-group',
    component: () => import('@/views/system/components/group/groupList/subordinate-group.vue'),
    meta: { title: '下级集团' },
    props: true
  },
  // // 个人资料
  {
    path: 'edit-login-information',
    name: 'edit-login-information',
    component: () => import('@/views/system/components/personal/edit-login-information.vue'),
    meta: { title: '编辑资料' },
    props: true
  },
  {
    path: 'edit-modify-password',
    name: 'edit-modify-password',
    component: () => import('@/views/system/components/personal/edit-modify-password.vue'),
    meta: { title: '修改密码' },
    props: true
  },
  // // // 代理设置
  {
    path: 'proxy-settings',
    name: 'proxy-settings',
    component: () => import('@/views/system/components/personal/proxy-settings/index.vue'),
    meta: { title: '代理设置', rekeepAlive: true, scrollTop: 0, name: 'proxySettings' },
    props: true
  },
  {
    path: 'details-proxy-settings/:id/:signId',
    name: 'details-proxy-settings',
    component: () => import('@/views/system/components/personal/proxy-settings/details-proxy-settings.vue'),
    meta: { title: '代理详情' },
    props: true
  },
  // // // 我的日程
  {
    path: 'new-my-schedule',
    name: 'new-my-schedule',
    component: () => import('@/views/system/new-my-schedule.vue'),
    meta: { title: '我的日程', rekeepAlive: true, scrollTop: 0, name: 'newMySchedule' },
    props: true
  },
  {
    path: 'new-edit-schedule/:id?',
    name: 'new-edit-schedule',
    component: () => import('@/views/system/components/personal/meeting-room-schedule/new-edit-schedule.vue'),
    meta: { title: '新增/编辑' },
    props: true
  },
  {
    path: 'edit-schedule/:id?',
    name: 'edit-schedule',
    component: () => import('@/views/system/components/personal/my-schedule/edit-schedule.vue'),
    meta: { title: '新增/编辑' },
    props: true
  },
  {
    path: 'details-schedule/:id',
    name: 'details-schedule',
    component: () => import('@/views/system/components/personal/my-schedule/details-schedule.vue'),
    meta: { title: '日程详情' },
    props: true
  },
  // // // 日程列表
  {
    path: 'schedule-list',
    name: 'schedule-list',
    component: () => import('@/views/system/components/personal/schedule-list/index.vue'),
    meta: { title: '日程列表', rekeepAlive: true, scrollTop: 0, name: 'scheduleList' },
    props: true
  },
  // // // 分组列表
  {
    path: 'personnel-grouping',
    name: 'personnel-grouping',
    component: () => import('@/views/system/components/personal/personnel-grouping/index.vue'),
    meta: { title: '分组列表', rekeepAlive: true, scrollTop: 0, name: 'personnelGrouping' },
    props: true
  },
  {
    path: 'edit-personnel-grouping/:id?',
    name: 'edit-personnel-grouping',
    component: () => import('@/views/system/components/personal/personnel-grouping/edit-personnel-grouping.vue'),
    meta: { title: '新增/编辑' },
    props: true
  },
  {
    path: 'details-personnel-grouping/:id',
    name: 'details-personnel-grouping',
    component: () => import('@/views/system/components/personal/personnel-grouping/details-personnel-grouping.vue'),
    meta: { title: '分组详情' },
    props: true
  }
]
