import Homelayout from '@/views/home' // 主页面结构

export default [{
  path: '/management-decision',
  component: Homelayout,
  children: [
    {
      path: 'cargo-flow',
      name: 'cargoFlow',
      component: () => import('@/views/management-decision/cargo-flow.vue'),
      meta: { title: '货物流水', keepAlive: true, scrollTop: 0, name: 'signOffFlow' }
    },
    {
      path: 'sign-off-flow',
      name: 'sign-off-flow',
      component: () => import('@/views/management-decision/sign-off-flow.vue'),
      meta: { title: '签核流水', keepAlive: true, scrollTop: 0, name: 'signOffFlow' }
    },
    {
      path: 'new-customer',
      name: 'new-customer',
      component: () => import('@/views/management-decision/new-customer.vue'),
      meta: { title: '新增客户', keepAlive: true, scrollTop: 0, name: 'newCustomer' }
    },
    {
      path: 'development-activities',
      name: 'development-activities',
      component: () => import('@/views/management-decision/development-activities.vue'),
      meta: { title: '开发活动', keepAlive: true, scrollTop: 0, name: 'developmentActivities' }
    },
    {
      path: 'performance-analysis',
      name: 'performance-analysis',
      component: () => import('@/views/management-decision/performance-analysis.vue'),
      meta: { title: '业绩分析', keepAlive: true, scrollTop: 0, name: 'performanceAnalysis' }
    }
  ]
}]
