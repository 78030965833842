<script>
/**
 * 传送门
 * 需要传送的话 必须使用Id, 格式例如： T_aaa, T_bbb, 'T_xxx'
 * 有的地方没有这个规则,之后一直用这个格式Id
 * 使用当前组件时，可以加v-if="isShow"
 * data: {
 *  isShow = false
 * }
 * mounted () {
 *  this.isShow = true
 * }
 * 这样使用是为了控制 Teleport 组件挂载时机
 * 避免可能报不必要的错误
 */
export default {
  props: {
    to: { // 传送到指定 #id 元素
      type: String,
      required: true
    },
    disabled: { // 是否传送
      type: Boolean,
      default: true
    }
  },
  /**
   * 配合 provide 使用
   * 父组件 跟 data 同个层级
   * provide: function () {
   *   return {
   *     parent: this
   *   }
   * }
   * inject 接收
   */
  // inject: ['parent'],
  watch: {
    disabled (val) {
      if (this.disabled) { // 把当前的DOM元素 向目标元素传送过去
        document.querySelector(this.to).appendChild(this.$el)
      } else { // 调用父组件的方法， 再将当前的DOM元素还原的原来的位置
        // this.parent.toSourceDom(this.$el) // .toSourceDom父组件的方法
        document.querySelector(this.to).innerHTML = '' // 清空节点下的所有节点
      }
    }
  },
  mounted () {
    if (this.disabled) {
      document.querySelector(this.to).innerHTML = '' // 清空节点下的所有节点
      document.querySelector(this.to).appendChild(this.$el)
    }
  },
  /**
   *  render方法中，如果有变量得使用{}
   *  this.$scopedSlots.default() 表示预留一个插槽
   */
  render () {
    return <div class="Teleport">{this.$scopedSlots.default()}</div>
  },
  beforeDestroy () {
    if (!this.to) return
    if (!document.querySelector(this.to)) return
    document.querySelector(this.to).innerHTML = '' // 清空节点下的所有节点
  }
}
</script>
<style scoped>
.Teleport {
  width: 100%;
  height: 100%;
}
</style>
