import store from '@/store/index'
/**
 * 业绩回款&&历史业绩业绩审核修改 上传图片是否必传  徐华
 * */
export const zhanjiangXuhuaAchievement = 'zhanjiang:xuhua:achievement'

/**
 * 客户提货 上传图片是否必传  徐华
 */
export const stockUpApplyNotImg = 'stockUp:apply:notImg'

/**
 *  禁用大单位
 */
export const filterPrivateUnits = (i) => {
  return store.getters.privateUnits.includes(i)
}
