import Homelayout from '@/views/home' // 主页面结构

export default [{
  path: '/purchase-order',
  component: Homelayout,
  children: [
    {
      path: 'new-order',
      name: 'new-order',
      component: () => import('@/views/purchase-order/new-order.vue'),
      meta: { title: '新加订单', keepAlive: true, scrollTop: 0, name: 'newOrder' }
    },
    {
      path: 'my-order',
      name: 'purchase-my-order',
      component: () => import('@/views/purchase-order/my-order.vue'),
      meta: { title: '我的订单', keepAlive: true, scrollTop: 0, name: 'newMyOrder' }
    },
    {
      path: 'advance-payment',
      name: 'purchase-advance-payment',
      component: () => import('@/views/purchase-order/advance-payment.vue'),
      meta: { title: '预存货款', keepAlive: true, scrollTop: 0, name: 'newAdvancePayment' }
    },
    {
      path: 'shopping-cart',
      name: 'purchase-shopping-cart',
      component: () => import('@/views/purchase-order/shopping-cart.vue'),
      meta: { title: '购物车', keepAlive: true, scrollTop: 0, name: 'purchaseShoppingCart' }
    },
    {
      path: 'my-collection',
      name: 'purchase-my-collection',
      component: () => import('@/views/purchase-order/my-collection.vue'),
      meta: { title: '我的收藏', keepAlive: true, scrollTop: 0, name: 'purchaseMyCollection' }
    },
    {
      path: 'picking-management',
      name: 'purchase-picking-management',
      component: () => import('@/views/purchase-order/picking-management.vue'),
      meta: { title: '货物管理', keepAlive: true, scrollTop: 0, name: 'newPickingManagement' }
    }
  ]
}]
