import Homelayout from '@/views/home' // 主页面结构
// import ReturnPage from '@/views/return-page' // 返回页面结构

export default [{
  path: '/distributor',
  component: Homelayout,
  children: [
    {
      path: 'dealer-order',
      name: 'dealer-order',
      component: () => import('@/views/distributor/dealer-order.vue'),
      meta: { title: '经销商订单', keepAlive: true, scrollTop: 0, name: 'dealerOrder' }
    },
    {
      path: 'purchase-order',
      name: 'purchase-order',
      component: () => import('@/views/distributor/purchase-order.vue'),
      meta: { title: '采购订单', keepAlive: true, scrollTop: 0, name: 'purchaseOrder' }
    },
    {
      path: 'advance-payment',
      name: 'dis-advance-payment',
      component: () => import('@/views/distributor/advance-payment.vue'),
      meta: { title: '预存货款', keepAlive: true, scrollTop: 0, name: 'disAdvancePayment' }
    },
    {
      path: 'dealer-performance',
      name: 'dis-dealer-performance',
      component: () => import('@/views/distributor/dealer-performance.vue'),
      meta: { title: '经销商业绩', keepAlive: true, scrollTop: 0, name: 'dealerPerformance' }
    },
    {
      path: 'business-log',
      name: 'business-log',
      component: () => import('@/views/distributor/business-log.vue'),
      meta: { title: '扶商日志' }
    },
    {
      path: 'employee-self-purchase',
      name: 'dis-employee-self-purchase',
      component: () => import('@/views/distributor/employee-self-purchase.vue'),
      meta: { title: '员工自购', keepAlive: true, scrollTop: 0, name: 'disEmployeeSelfPurchase' }
    }
  ]
}]
